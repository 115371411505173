var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section-main"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"px-5",attrs:{"cols":"12","md":"9","lg":"10"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',[_c('h4',[_vm._v(_vm._s(_vm.title)+" "+_vm._s(_vm.id))])]),(_vm.contract)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"10","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("عنوان")]),_vm._v(" "+_vm._s(_vm.contract.title)+" ")])]),_c('v-col',{attrs:{"cols":"2","lg":"1"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("وضعیت")]),_c('v-icon',{class:{
                      'active-icon': _vm.contract.isActive,
                      'inactive-icon': !_vm.contract.isActive,
                    }},[_vm._v(" "+_vm._s(_vm.contract.isActive ? "mdi-check-circle" : "mdi-cancel")+" ")]),_c('span',[_vm._v(_vm._s(_vm.contract.isActive ? "فعال" : "غیرفعال"))])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("مبلغ")]),_vm._v(" "+_vm._s(_vm.commaSep(_vm.contract.mainPrice))+" ريال ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("شماره قرارداد")]),_vm._v(" "+_vm._s(_vm.contract.number || "ـ")+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("گروه قرارداد")]),_vm._v(" "+_vm._s(_vm.contract.groupTitle + " ")+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("پروژه")]),_vm._v(" "+_vm._s(_vm.contract.projectTitle + " ")+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("طرف قرارداد")]),_c('router-link',{attrs:{"to":{
                      name: 'Customer',
                      params: {
                        id: Number(_vm.contract.customerFk),
                      },
                    }}},[_c('span',[_vm._v(_vm._s(_vm.contract.customerName))])])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("تاریخ شروع")]),_vm._v(" "+_vm._s(_vm.toPersianDate(_vm.contract.startDate))+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("تاریخ پایان")]),_vm._v(" "+_vm._s(_vm.toPersianDate(_vm.contract.endDate))+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("توضیحات")]),_vm._v(" "+_vm._s(_vm.contract.notation)+" ")])]),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("برچسب ها")]),_vm._v(" "+_vm._s(_vm.contract.notation)+" ")])])],1)],1):_vm._e()],1)],1),_c('v-col',{staticClass:"section__banks-sidbar",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('MenuLeft')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }