<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class="rounded-lg">
            <v-dialog v-model="showDeleteDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="showDeleteDialog = false"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="showAddDialog"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="showAddDialog = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="form">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="عنوان گروه مشتری"
                          hide-details
                          outlined
                          dense
                          v-model="addData.customerGroupTitle"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="600"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="عنوان گروه مشتری"
                          hide-details
                          outlined
                          dense
                          v-model="editData.data.customerGroupTitle"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="updateCustomer()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <Help
                    @ShowHelp="getDataShowHelp"
                    :sendDataHelp="sampleHelp"
                  ></Help>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="showAddDialog = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="groups"
                  hide-default-footer
                  :items-per-page="pageSize"
                  :server-items-length="total"
                  :page="pageNo"
                  :fixed-header="true"
                  :loading="loading"
                >
                  <template v-slot:[`item.index`]="{ index }">
                    {{ startIndex + index + 1 }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="openDeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col cols="12" v-if="pagesCount > 1">
                    <v-pagination
                      :value="pageNo"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../../components/MenuLeft.vue";
import VCardTitleClose from "../../../components/VCardTitleClose";
import Help from "../../../components/Help.vue";
import { mapState } from "vuex";
import { customerMixin } from "../mixins";
import { reactive } from "vue";
export default {
  mixins: [customerMixin],
  name: "CustomerGroupsList",
  components: {
    VCardTitleClose,
    MenuLeft,
    Help,
  },
  data() {
    return {
      search: "",
      title: "دسته بندی مشتریان",
      showDeleteDialog: false,
      showAddDialog: false,
      editForm: false,
      addData: {},
      editData: {
        url: "customergroup/update/",
        data: { customerGroupTitle: null },
      },
      removeData: null,
      headers: [
        {
          text: "#",
          value: "index",
          align: "start",
          sortable: false,
        },

        {
          text: "عنوان گروه مشتری",
          align: "start",
          value: "customerGroupTitle",
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      groups: (state) => state.customers.groups.items,
      loading: (state) => state.customers.groups.loading,
      pageNo: (state) => state.customers.groups.pageNo,
      pageSize: (state) => state.customers.groups.pageSize,
      total: (state) => state.customers.groups.total,      
      
      refreshList: (state) => state.customers.groups.refreshList,
    }),
    pagesCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    startIndex() {
      return (this.pageNo - 1) * this.pageSize;
    },
  },
  created() {
    this.getCustomerGroups(1, 10, "");
  },
  watch: {
    refreshList() {
      this.getCustomerGroups(
        this.pageNo,
        this.pageSize,
        this.query
      );
    },
  },
  methods: {
    handlePageChange(page) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getCustomerGroups(page, this.pageSize, this.search);
    },
    searchBoxChanged() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getCustomerGroups(1, this.pageSize, this.search);
    },

    getDataShowHelp() {
      alert("help!!");
    },
    sampleHelp() {},

    openAddDialog() {
      this.addData = reactive({ customerGroupTitle: "" });
      this.showAddDialog = true;
    },
    addItem() {
      this.createCustomerGroup(this.addData);
      this.showAddDialog = false;
    },
    openFormEdit(item) {
      this.editForm = true;
      this.editData.data = {...item};
    },
    updateCustomer() {
      if (this.$refs.formEdit.validate()) {
        this.updateCustomerGroup(this.editData.data.customerGroupId, this.editData.data);
        this.editForm = false;
      }
    },
    openDeleteDialog(item) {
      this.showDeleteDialog = true;
      this.removeData = { ...item };
    },
    deleteItemConfirm() {
      this.deleteCustomerGroup(this.removeData.customerGroupId);
      this.showDeleteDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
