import Vue from "vue";
import Vuex from "vuex";
import mutations from "./mutations.js";
import actions from "./actions.js";
import state from "./state.js";
import getters from "./getters.js";
import project from "../latelier/imports/store/modules/project";
import customers from "../modules/customers/store";
import contracts from "../modules/contracts/store";
import projects from "../modules/projects/store";


Vue.use(Vuex);
export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    project,
    customers,
    contracts,
    projects,
  },
});
