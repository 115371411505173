<template>
  <section class="section-main">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <v-card class="rounded-lg">
            <v-card-title>
              <h4>{{ title }} {{ id }}</h4>
            </v-card-title>
            <v-card-text v-if="contract">
              <v-row>
                <v-col cols="10" lg="4">
                  <fieldset class="px-2">
                    <legend>عنوان</legend>
                    {{ contract.title }}
                  </fieldset>
                </v-col>
                <v-col cols="2" lg="1">
                  <fieldset class="px-2">
                    <legend>وضعیت</legend>
                    <v-icon
                      :class="{
                        'active-icon': contract.isActive,
                        'inactive-icon': !contract.isActive,
                      }"
                    >
                      {{
                        contract.isActive ? "mdi-check-circle" : "mdi-cancel"
                      }}
                    </v-icon>
                    <span>{{ contract.isActive ? "فعال" : "غیرفعال" }}</span>
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="3">
                  <fieldset class="px-2">
                    <legend>مبلغ</legend>
                    {{ commaSep(contract.mainPrice) }} ريال
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>شماره قرارداد</legend>
                    {{ contract.number || "ـ" }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>گروه قرارداد</legend>
                    {{ contract.groupTitle + " " }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>پروژه</legend>
                    {{ contract.projectTitle + " " }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>طرف قرارداد</legend>
                    <router-link
                      :to="{
                        name: 'Customer',
                        params: {
                          id: Number(contract.customerFk),
                        },
                      }"
                      ><span>{{ contract.customerName }}</span>
                    </router-link>
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>تاریخ شروع</legend>
                    {{ toPersianDate(contract.startDate) }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>تاریخ پایان</legend>
                    {{ toPersianDate(contract.endDate) }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="12">
                  <fieldset class="px-2">
                    <legend>توضیحات</legend>
                    {{ contract.notation }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="12">
                  <fieldset class="px-2">
                    <legend>برچسب ها</legend>
                    {{ contract.notation }}
                  </fieldset>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { customerMixin } from "@/modules/customers/mixins";
import { contractMixin } from "../mixins";
import { myMixin } from "../../../../mixins.js";
import MenuLeft from "@/components/MenuLeft.vue";

export default {
  mixins: [myMixin, contractMixin, customerMixin],
  components: {
    MenuLeft,
  },
  props: {
    id: {
      default: undefined,
    },
  },
  data() {
    return {
      title: "جزییات قرارداد",
    };
  },
  computed: {
    ...mapState({
      contract: (state) => state.contracts.item,
      loading: (state) => state.contracts.loading,
    }),
  },
  mounted() {
    console.log(this.id);
    this.getContract(Number(this.id));
  },
};
</script>
<style scoped>
fieldset {
  border-radius: 5px; /* Adjust the radius as needed */
  border: 2px solid #ccc; /* Optional: Add a border */
  padding: 10px; /* Optional: Add padding */
}
.active-icon {
  color: green;
}
.inactive-icon {
  color: red;
}
</style>
