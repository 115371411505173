<template>
  <section class="section__letters">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="9" lg="10" class="section__home-data px-5">
              <LettersList
                :title="title"
                :subTitle="customerName"
                :headers="headers"
                :sendList="listLetters"
                pageType="AllMessage"
                :loading="loading"
                @changed="optionChanged"
                @search="searchChanged"
                @toCustomerChanged="toCustomerChanged"
                @fromCustomerChanged="fromCustomerChanged"
              ></LettersList>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="section__home-sidbar">
              <MenuLeft></MenuLeft>
            </v-col>
          </v-row>
        </v-col>
        <!-- <v-col cols="12"></v-col> -->
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import LettersList from "./LettersList.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
import { contractMixin } from "@/modules/contracts/mixins";
import { customerMixin } from "@/modules/customers/mixins";
export default {
  mixins: [myMixin, customerMixin, contractMixin],
  name: "LettersPage",
  components: {
    MenuLeft,
    LettersList,
  },
  data() {
    return {
      title: "کلیه نامه ها",
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menu2: false,
      expanded: [],
      singleExpand: false,
      listLetters: null,
      loading: false,
      search: this.$route.query.phrase,
      currentPageOption: { page: 1, pageSize: 20 },
      headers: [
        {
          text: "نوع نامه",
          value: "messageTypeString",
          sortable: false,
          align: "center",
        },
        {
          text: "وضعیت نامه",
          value: "isReaded",
          sortable: false,
          align: "center",
        },
        {
          text: "موضوع نامه",
          value: "subject",
          sortable: false,
          align: "start",
        },
        {
          text: "فرستنده نامه",
          value: "messageFrom",
          sortable: false,
          align: "center",
        },
        {
          text: "گیرندگان",
          value: "messageTo",
          sortable: false,
          align: "center",
        },
        {
          text: "شماره نامه",
          value: "displayMessageNumber",
          align: "center",
          sortable: false,
        },
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "", value: "title7", align: "center", sortable: false },
        {
          text: "",
          value: "actions",
          align: "end",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getUrl", "getPageListSetting", "getCustomerName"]),
    customerName() {
      return (
        this.getCustomerName(this.$route.query.toCustomerId) ||
        this.getCustomerName(this.$route.query.fromCustomerId)
      );
    },
  },
  mounted() {
    this.getCustomers();
    this.loading = true;
    axios
      .get(`${this.getUrl}/message/list/`, {
        params: {
          Count: this.$route.query.pageSize,
          Page: this.$route.query.page,
          Language: 0,
          MessageType: 0,
          Phrase: this.$route.query.phrase,
          TypeNumber: "string",
          PageType: "AllMessage",
          projectId: this.$route.query.projectId || null,
          toCustomerId: this.$route.query.toCustomerId || null,
          fromCustomerId: this.$route.query.fromCustomerId || null,
        },
      })
      .then((data) => {
        this.listLetters = data.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    optionChanged(option) {
      if (
        option.page != this.currentPageOption.page ||
        option.itemsPerPage != this.currentPageOption.pageSize
      ) {
        this.getListLetters(option.page, option.itemsPerPage, this.search);
        this.currentPageOption.page = option.page;
        this.currentPageOption.pageSize = option.itemsPerPage;
      }
    },
    searchChanged(search) {
      console.log("searchChanged", search);
      this.$router.push({
        query: {
          page: 1,
          pageSize: 20,
          phrase: search,
          projectId: this.$route.query.projectId,
          toCustomerId: this.$route.query.toCustomerId,
          fromCustomerId: this.$route.query.fromCustomerId,
        },
      });
    },
    toCustomerChanged(customerId) {
      console.log("toCustomerChanged", customerId);
      this.$router.push({
        query: {
          page: 1,
          pageSize: 20,
          phrase: this.search,
          projectId: this.$route.query.projectId,
          toCustomerId: customerId,
          fromCustomerId: undefined,
        },
      });
    },
    fromCustomerChanged(customerId) {
      console.log("fromCustomerChanged", customerId);
      this.$router.push({
        query: {
          page: 1,
          pageSize: 20,
          phrase: this.search,
          projectId: this.$route.query.projectId,
          toCustomerId: undefined,
          fromCustomerId: customerId,
        },
      });
    },
    getListLetters(page, pageSize, search) {
      this.loading = true;
      axios
        .get(`${this.getUrl}/message/list/`, {
          params: {
            Count: pageSize,
            Page: page,
            Language: 0,
            MessageType: 0,
            Phrase: search,
            TypeNumber: "string",
            PageType: "AllMessage",
            projectId: this.$route.query.projectId,
            toCustomerId: this.$route.query.toCustomerId,
            fromCustomerId: this.$route.query.fromCustomerId,
          },
        })
        .then((data) => {
          this.listLetters = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {
    $route(to) {
      this.getListLetters(to.query.page, to.query.pageSize, to.query.phrase);
    },
  },
};
</script>

<style scoped>
.section__letters {
  background-color: #eeeeee !important;
  height: 100vh;
}
.router-link-active {
  text-decoration: none !important;
}
.border {
  border: 1px solid black !important;
}
</style>
