export const contractMixin = {
  methods: {
    createContract(data, language = 1) {
      return this.$store.dispatch("CREATE_CONTRACT", { language, data });
    },
    updateContract(id, data, language = 1) {
      return this.$store.dispatch("UPDATE_CONTRACT", { id, language, data });
    },
    deleteContract(id, language = 1) {
      return this.$store.dispatch("DELETE_CONTRACT", { id, language });
    },
    getContract(id, language = 1) {
      return this.$store.dispatch("GET_CONTRACT", { id, language });
    },
    getContracts(page = 1, count = -1, phrase = "", customerId = undefined) {
      return this.$store.dispatch("GET_CONTRACTS", {
        page,
        count,
        phrase,
        customerId,
      });
    },
    createContractGroup(data, language = 1) {
      this.$store.dispatch("CREATE_CONTRACT_GROUP", { data, language });
    },
    updateContractGroup(id, data, language = 1) {
      this.$store.dispatch("UPDATE_CONTRACT_GROUP", { id, data, language });
    },
    deleteContractGroup(id, language = 1) {
      this.$store.dispatch("DELETE_CONTRACT_GROUP", { id, language });
    },
    getContractGroups(page = 1, count = -1, phrase = "") {
      this.$store.dispatch("GET_CONTRACT_GROUPS", {
        page,
        phrase,
        count,
      });
    },
  },
};
