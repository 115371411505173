<template>
  <section class="section-main">
    <v-dialog v-model="showDeleteDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="showDeleteDialog = false"
            >لغو</v-btn
          >
          <v-btn color="error" variant="text" @click="deleteItemConfirm"
            >بله</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="showAddDialog"
    >
      <v-card class="rounded-lg">
        <v-card-title-close @close="showAddDialog = false">
          ایجاد
        </v-card-title-close>
        <v-form ref="addForm">
          <v-card-text v-if="addData">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :rules="requiredRule"
                  label="عنوان گروه مشتری"
                  hide-details
                  outlined
                  dense
                  v-model="addData.contractGroupTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  label="هزینه ای"
                  hide-details
                  dense
                  v-model="addData.isCost"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn v-on:click="addContract()" color="success">افزودن</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="showEditDialog"
    >
      <v-card class="rounded-lg">
        <v-card-title-close @close="showEditDialog = false">
          ویرایش
        </v-card-title-close>
        <v-form ref="editForm">
          <v-card-text v-if="editData.data">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  :rules="requiredRule"
                  label="عنوان گروه مشتری"
                  hide-details
                  outlined
                  dense
                  v-model="editData.data.contractGroupTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  label="هزینه ای"
                  hide-details
                  dense
                  v-model="editData.data.isCost"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn v-on:click="updateContract()" color="success">ویرایش</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <v-card>
            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <Help
                    @ShowHelp="getDataShowHelp"
                    :sendDataHelp="sampleHelp"
                  ></Help>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="showAddDialog = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  :items-per-page="pageSize"
                  :server-items-length="total"
                  :page="pageNo"
                  :fixed-header="true"
                  :loading="loading"
                >
                  <template v-slot:[`item.index`]="{ index }">
                    {{ startIndex + index + 1 }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="openDeleteDialog(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col cols="12" v-if="pagesCount > 1">
                    <v-pagination
                      :value="pageNo"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            :items="getContractgroupList"
            :insertItemData="addData"
            v-on:getListBySearch="getSearch"
            v-on:getList="getPage"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="گروه قرارداد"
                        hide-details
                        outlined
                        dense
                        v-model="addData.contractGroupTitle"
                        :rules="requiredRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="هزینه ای"
                        hide-details
                        dense
                        v-model="addData.isCost"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="editForm">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="گروه قرارداد"
                        hide-details
                        outlined
                        dense
                        v-model="editData.data.contractGroupTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="هزینه ای"
                        hide-details
                        dense
                        v-model="editData.data.isCost"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
      </v-row> -->
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../../components/MenuLeft";
import Help from "../../../components/Help.vue";
import VCardTitleClose from "../../../components/VCardTitleClose";
import { mapState } from "vuex";
import { myMixin } from "../../../../mixins.js";
import { contractMixin } from "../mixins";
import { reactive } from "vue";

export default {
  name: "ContractGroupsList",
  mixins: [myMixin, contractMixin],
  components: {
    VCardTitleClose,
    MenuLeft,
    Help,
  },
  data() {
    return {
      title: "گروه های قرارداد",
      search: "",
      showDeleteDialog: false,
      showAddDialog: false,
      showEditDialog: false,
      addData: {},
      editData: {},
      removeData: {},
      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          sortable: false,
        },
        {
          text: "گروه قرارداد",
          align: "start",
          value: "contractGroupTitle",
          sortable: false,
        },
        {
          text: "نوع قرارداد",
          align: "start",
          value: "contractType",
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.contracts.groups.items,
      loading: (state) => state.contracts.groups.listLoading,
      pageNo: (state) => state.contracts.groups.pageNo,
      pageSize: (state) => state.contracts.groups.pageSize,
      total: (state) => state.contracts.groups.total,

      customers: (state) => state.customers.items,

      refreshList: (state) => state.contracts.groups.refreshList,
    }),
    pagesCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    startIndex() {
      return (this.pageNo - 1) * this.pageSize;
    },
  },
  created() {
    this.getContractGroups(1, 10, "");
  },
  watch: {
    refreshList() {
      this.getContractGroups(this.pageNo, this.pageSize, this.query);
    },
  },
  methods: {
    getData(item) {
      this.getContractGroup(item.contractGroupId)
        .then((response) => {
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchBoxChanged() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getContractGroups(1, this.pageSize, this.search);
    },

    getDataShowHelp() {
      alert("help!!");
    },
    sampleHelp() {},

    handlePageChange(pageNo) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getContractGroups(pageNo, this.pageSize, this.search);
    },

    openAddDialog() {
      this.addData = reactive({
        contractGroupTitle: null,
        isCost: false,
      });
      this.showAddDialog = true;
    },
    addContract() {
      if (this.$refs.addForm.validate()) {
        this.createContractGroup(this.addData);
        this.showAddDialog = false;
      }
    },
    openFormEdit(item) {
      this.showEditDialog = true;
      this.editData.data = { ...item };
    },
    updateContract() {
      if (this.$refs.editForm.validate()) {
        this.updateContractGroup(
          this.editData.data.contractGroupId,
          this.editData.data
        );
        this.showEditDialog = false;
      }
    },
    openDeleteDialog(item) {
      this.showDeleteDialog = true;
      this.removeData = { ...item };
    },
    deleteItemConfirm() {
      this.deleteContractGroup(this.removeData.contractGroupId);
      this.showDeleteDialog = false;
    },
  },
};
</script>
