<template>
  <section class="section-main">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <v-card>
            <v-dialog v-model="showDeleteDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="showAddDialog"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="showAddDialog = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="addForm">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          :rules="nameRules"
                          label="عنوان پروژه"
                          outlined
                          dense
                          hide-details
                          v-model="addData.title"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showStartDP = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="addData.startDate"
                              id="addData.startDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ شروع"
                              @click="showStartDP = true"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="addData.startDate"
                              :show="showStartDP"
                              :editable="true"
                              :auto-submit="true"
                              custom-input="addData.startDate"
                              @close="showStartDP = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showFinishDP = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="addData.finishDate"
                              id="finishDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ اتمام"
                              @click="showFinishDP = true"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="addData.finishDate"
                              :show="showFinishDP"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#finishDate"
                              @close="showFinishDP = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          label="گروه پروژه"
                          hide-details
                          outlined
                          :items="groupItems"
                          item-text="projectGroupTitle"
                          item-value="projectGroupId"
                          dense
                          v-model="addData.groupId"
                          :rules="requiredRule"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Edit -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="showEditDialog"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="showEditDialog = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="editForm">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          :rules="nameRules"
                          label="عنوان پروژه"
                          outlined
                          dense
                          hide-details
                          v-model="editData.title"
                        ></v-text-field> </v-col
                      ><v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showStartDP = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="editData.startDate"
                              id="editData.startDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ شروع"
                              @click="showStartDP = true"
                              :rules="requiredRule"
                            ></v-text-field>
                            <date-picker
                              v-model="editData.startDate"
                              :show="showStartDP"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#editData.startDate"
                              @close="showStartDP = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showFinishDP = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="editData.finishDate"
                              id="editData.finishDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ پایان"
                              @click="showFinishDP = true"
                              :rules="requiredRule"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="editData.finishDate"
                              :show="showFinishDP"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#editData.finishDate"
                              @close="showFinishDP = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          label="گروه پروژه"
                          hide-details
                          outlined
                          :items="groupItems"
                          item-text="projectGroupTitle"
                          item-value="projectGroupId"
                          dense
                          v-model="editData.groupId"
                          :rules="requiredRule"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-title>
              {{ title }}
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="2">
                  <v-btn class="mr-2 success" @click="showAddDialog = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="search"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="جستجو"
                    @input="searchBoxChanged"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  :items-per-page="pageSize"
                  :server-items-length="total"
                  :page="pageNo"
                  :fixed-header="true"
                  :loading="loading"
                >
                  <template v-slot:[`item.index`]="{ index }">
                    {{ startIndex + index + 1 }}
                  </template>
                  <template v-slot:[`item.projectTitle`]="{ item }">
                    <router-link
                      :to="{
                        name: 'Project',
                        params: {
                          id: Number(item.projectId),
                        },
                      }"
                      ><span>{{ item.projectTitle }}</span>
                    </router-link>
                  </template>
                  <template v-slot:[`item.startDate`]="{ item }">
                    <span>{{ toPersianDate(item.startDate) }}</span>
                  </template>
                  <template v-slot:[`item.finishDate`]="{ item }">
                    <span>{{ toPersianDate(item.finishDate) }}</span>
                  </template>
                  <template v-slot:[`item.projectGroup`]="{ item }">
                    <span>{{
                      item.projectGroupFkNavigation.projectGroupTitle
                    }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col cols="12" v-if="pagesCount > 1">
                    <v-pagination
                      :value="pageNo"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../../components/MenuLeft";
import VCardTitleClose from "../../../components/VCardTitleClose";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { myMixin } from "../../../../mixins.js";
import { projectMixin } from "../mixins";
import moment from "jalali-moment";
import { mapGetters, mapState } from "vuex";
import { reactive } from "vue";
export default {
  name: "ProjectsList",
  mixins: [myMixin, projectMixin],
  components: {
    VCardTitleClose,
    MenuLeft,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      title: "پروژه ها",
      showDeleteDialog: false,
      showAddDialog: false,
      showEditDialog: false,
      showFinishDP: false,
      showStartDP: false,
      image: null,
      search: null,
      activator: null,
      attach: null,
      addData: {},
      editData: {},
      removeData: {},
      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          sortable: false,
        },
        {
          text: "عنوان",
          align: "start",
          value: "projectTitle",
          sortable: false,
        },
        {
          text: "تاریخ شروع",
          align: "start",
          value: "startDate",
          sortable: false,
        },
        {
          text: "تاریخ اتمام",
          align: "start",
          value: "finishDate",
          sortable: false,
        },
        {
          text: "دسته",
          align: "start",
          value: "projectGroup",
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.projects.items,
      loading: (state) => state.projects.listLoading,
      pageNo: (state) => state.projects.pageNo,
      pageSize: (state) => state.projects.pageSize,
      total: (state) => state.projects.total,

      groupItems: (state) => state.projects.groups.items,
      groupsLoading: (state) => state.projects.groups.listLoading,

      customers: (state) => state.customers.items,
      refreshList: (state) => state.projects.refreshList,
    }),
    ...mapGetters(["getUrl"]),
    pagesCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    startIndex() {
      return (this.pageNo - 1) * this.pageSize;
    },
    customerName() {
      return this.$store.getters.getCustomerName(this.$route.query.customerId);
    },
  },
  created() {
    this.getProjects(1, 10, "");
    this.getProjectGroups();
  },
  watch: {
    refreshList() {
      this.getProjects(
        this.pageNo,
        this.pageSize,
        this.query,
        this.$route.query.customerId
      );
    },
  },
  methods: {
    searchBoxChanged(search) {
      this.getProjects(1, this.pageSize, search);
    },
    handlePageChange(pageNo) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getProjects(pageNo, this.pageSize, this.search);
    },
    getData(item) {
      this.getProject(item.projetctId)
        .then((response) => {
          this.editData = response.data;
          this.editData.startDate = this.toPersianDate(response.data.startDate);
          this.editData.finishDate = this.toPersianDate(
            response.data.finishDate
          );
          this.editData.tags = response.data.tags.map((e) => e.phrase);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    remove() {
      this.$refs.image.src = "";
    },
    updateStartDate(date) {
      this.addData.startDate = moment(date)
      .locale("fa")
      .format("YYYY/M/D");
    },
    updateFinishDate(date) {
      this.addData.finishDate = moment(date)
      .locale("fa")
      .format("YYYY/M/D");
    },
    updateEditFinishDate(date) {
      this.editData.data.finishDate = moment(date)
        .locale("fa")
        .format("YYYY/M/D");
    },
    updateEditStartDate(date) {
      this.editData.data.startDate = moment(date)
        .locale("fa")
        .format("YYYY/M/D");
    },
    openAddDialog() {
      this.showAddDialog = true;
      this.addData = reactive({
        title: null,
        cover: null,
        finishDate: null,
        startDate: null,
        groupId: null,
        tags: [],
      });
    },
    addItem() {
      if (this.$refs.addForm.validate()) {
        console.log("addData:", this.addData);
        this.addData.startDate = moment
          .from(this.addData.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss");
        this.addData.finishDate = moment
          .from(this.addData.finishDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss");
        this.createProject(this.addData);
        this.showAddDialog = false;
      }
    },
    openFormEdit(item) {
      this.showEditDialog = true;
      this.getProject(item.projectId)
        .then((data) => {          
          this.editData = reactive({
            id: data.id,
            title: data.title,
            cover: data.cover,
            finishDate: this.toPersianDate(data.finishDate),
            startDate: this.toPersianDate(data.startDate),
            groupId: data.groupId,
            tags: data.tags.map((e) => e.phrase),
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    editItem() {
      if (this.$refs.editForm.validate()) {
        this.editData.startDate = moment
          .from(this.editData.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss");
        this.editData.finishDate = moment
          .from(this.editData.finishDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss");
        //this.tags = this.tags?.join(",") || "";
        this.updateProject(this.editData.id, this.editData);
        this.showEditDialog = false;
      }
    },
    deleteItem(item) {
      this.showDeleteDialog = true;
      this.removeData = item;
    },
    deleteItemConfirm() {
      this.deleteProject(this.removeData.projectId);
      this.closeDelete();
    },

    closeDelete() {
      this.showDeleteDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
