<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class="rounded-lg">
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="addForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form v-model="valid" ref="form">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="پیشوند"
                          outlined
                          dense
                          v-model="addData.preTitle"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام"
                          outlined
                          dense
                          v-model="addData.firstName"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام خانوادگی"
                          outlined
                          dense
                          v-model="addData.lastName"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام پدر"
                          outlined
                          dense
                          v-model="addData.fatherName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showCalendar = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="addData.birthDate"
                              id="addData.birthDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ تولد"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="addData.birthDate"
                              :show="showCalendar"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#addData.birthDate"
                              @close="showCalendar = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کد ملی"
                          outlined
                          dense
                          v-model="addData.nationalCode"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" lg="12">
                        <v-select
                          label="شرکت"
                          outlined
                          :items="gecompanyList.records"
                          item-text="companyName"
                          item-value="manCompanyId"
                          dense
                          v-model="addData.parentCompanyFk"
                        >
                        </v-select>
                      </v-col> -->
                      <v-col cols="12" lg="6">
                        <v-select
                          label="استان"
                          outlined
                          :items="listProvince.records"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="addData.provinceFk"
                          v-on:change="getListCityById(addData.provinceFk)"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          label="شهر"
                          outlined
                          :items="listCity"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="addData.cityFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-select
                          label="وضعیت تاهل"
                          outlined
                          :items="maritalType"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="addData.genderFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="آدرس ایمیل"
                          outlined
                          dense
                          v-model="addData.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کد پستی"
                          outlined
                          dense
                          v-model="addData.postalCode"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="شماره تماس"
                          outlined
                          dense
                          v-model="addData.phone"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" lg="3">
                        <v-text-field
                          label="شماره موبایل"
                          outlined
                          dense
                          v-model="addData.mobile"
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="12" lg="3">
                        <v-checkbox
                          label="واتس اپ"
                          outlined
                          dense
                          v-model="addData.whatsApp"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-text-field
                          label="آدرس"
                          outlined
                          dense
                          v-model="addData.address"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="addData.organPost"
                          :items="getorgpostList.records"
                          item-text="orgPostTitle"
                          item-value="orgPostId"
                          outlined
                          dense
                          chips
                          small-chips
                          label="پست های سازمانی"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>

                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="شماره موبایل"
                          outlined
                          dense
                          v-model="addData.userName"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کلمه عبور"
                          outlined
                          dense
                          v-model="addData.passWord"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-select
                          label="دسترسی ها"
                          outlined
                          :items="listRoles"
                          dense
                          v-model="addData.roleNames"
                          :rules="requiredMultiRule"
                          multiple
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-checkbox
                          label="فعال"
                          outlined
                          dense
                          v-model="addData.isActive"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="پیشوند"
                          outlined
                          dense
                          v-model="editData.data.preTitle"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام"
                          outlined
                          dense
                          v-model="editData.data.firstName"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام خانوادگی"
                          outlined
                          dense
                          v-model="editData.data.lastName"
                          :rules="requiredRule"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-text-field
                          label="نام پدر"
                          outlined
                          dense
                          v-model="editData.data.fatherName"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="showCalendar2 = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="editData.data.birthDate"
                              id="editData.data.birthDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ تولد"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="editData.data.birthDate"
                              :show="showCalendar2"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#editData.data.birthDate"
                              @close="showCalendar2 = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کد ملی"
                          outlined
                          dense
                          v-model="editData.data.nationalCode"
                        ></v-text-field>
                      </v-col>
                      <!-- <v-col cols="12" lg="12">
                        <v-select
                          label="شرکت"
                          outlined
                          :items="gecompanyList.records"
                          item-text="companyName"
                          item-value="manCompanyId"
                          dense
                          v-model="editData.data.parentCompanyFk"
                        >
                        </v-select>
                      </v-col> -->
                      <v-col cols="12" lg="6">
                        <v-select
                          label="استان"
                          outlined
                          :items="listProvince.records"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="editData.data.provinceFk"
                          v-on:change="
                            getListCityById(editData.data.provinceFk)
                          "
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-select
                          label="شهر"
                          outlined
                          :items="listCity"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="editData.data.cityFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-select
                          label="وضعیت تاهل"
                          outlined
                          :items="maritalType"
                          item-text="title"
                          item-value="id"
                          dense
                          v-model="editData.data.genderFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="آدرس ایمیل"
                          outlined
                          dense
                          v-model="editData.data.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کد پستی"
                          outlined
                          dense
                          v-model="editData.data.postalCode"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="شماره تماس"
                          outlined
                          dense
                          v-model="editData.data.phone"
                        ></v-text-field>
                      </v-col>

                      <!-- <v-col cols="12" lg="3">
                        <v-text-field
                          label="شماره موبایل"
                          outlined
                          dense
                          v-model="editData.data.mobile"
                        ></v-text-field>
                      </v-col> -->
                      <v-col cols="12" lg="3">
                        <v-checkbox
                          label="واتس اپ"
                          outlined
                          dense
                          v-model="editData.data.whatsApp"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-text-field
                          label="آدرس"
                          outlined
                          dense
                          v-model="editData.data.address"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-autocomplete
                          v-model="editData.data.organPost"
                          :items="getorgpostList.records"
                          item-text="orgPostTitle"
                          item-value="orgPostId"
                          outlined
                          dense
                          chips
                          small-chips
                          label="پست های سازمانی"
                          multiple
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12">
                        <v-divider />
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="شماره موبایل"
                          outlined
                          dense
                          v-model="editData.data.userName"
                          :rules="requiredRule"
                          readonly
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="6">
                        <v-text-field
                          label="کلمه عبور"
                          outlined
                          dense
                          v-model="editData.data.passWord"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="12">
                        <v-select
                          label="دسترسی ها"
                          outlined
                          :items="listRoles"
                          dense
                          v-model="editData.data.roleNames"
                          :rules="requiredMultiRule"
                          multiple
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="3">
                        <v-checkbox
                          label="فعال"
                          outlined
                          dense
                          v-model="editData.data.isActive"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-3 mt-3"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="sendShowHelp"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>راهنما</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="addForm = true">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
              ></v-text-field>
            </v-card-title>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="getPersonList.records"
                  hide-default-footer
                  :items-per-page="20"
                  :server-items-length="getPersonList.record_count"
                  :page="1"
                  :fixed-header="true"
                  :loading="getLoading"
                >
                  <template v-slot:[`item.isActive`]="{ item }">
                    <v-simple-checkbox
                      disabled
                      dense
                      v-model="item.isActive"
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col
                    cols="12"
                    v-if="Math.ceil(getPersonList?.record_count / 20) > 1"
                  >
                    <v-pagination
                      v-model="page"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import VCardTitleClose from "../../components/VCardTitleClose";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { myMixin } from "../../../mixins.js";
import { mapGetters } from "vuex";
import moment from "jalali-moment";
export default {
  mixins: [myMixin],
  name: "PersonLt",
  components: {
    MenuLeft,
    VCardTitleClose,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      search: "",
      page: 1,
      pageSize: 10,
      title: "اشخاص",
      dialogDelete: false,
      addForm: false,
      editForm: false,
      valid: true,
      menu: false,
      showCalendar: false,
      showCalendar2: false,
      post: [],
      listProvince: [],
      listRoles: [],
      listAllCity: [],
      listCity: [],
      usertypeList: [],
      maritalType: [
        {
          title: "مجرد",
          id: 1,
        },
        {
          title: "متاهل",
          id: 2,
        },
      ],
      addData: {
        url: "person/create/",
        userName: null,
        address: null,
        preTitle: null,
        firstName: null,
        lastName: null,
        fatherName: null,
        birthDate: null,
        nationalCode: null,
        parentCompanyFk: null,
        provinceFk: null,
        cityFk: null,
        genderFk: null,
        postalCode: null,
        email: null,
        phone: null,
        organPost: [],
        mobile: null,
        whatsApp: true,
        fax: null,
        isActive: true,
        userTypeFk: null,
        passWord: null,
        roleNames: [],
        test: this.$refs,
      },
      editData: {
        url: "person/update/",
        data: {
          userName: null,
          organPost: [],
          address: null,
          preTitle: null,
          firstName: null,
          lastName: null,
          fatherName: null,
          birthDate: null,
          nationalCode: null,
          parentCompanyFk: 0,
          provinceFk: 0,
          cityFk: 0,
          genderFk: 0,
          postalCode: null,
          email: null,
          phone: null,
          mobile: null,
          whatsApp: true,
          fax: null,
          isActive: true,
          userTypeFk: 0,
          passWord: null,
          roleNames: [],
        },
      },
      removeData: null,
      headers: [
        {
          text: "پیشوند",
          align: "start",
          value: "preTitle",
        },
        {
          text: "نام",
          align: "start",
          value: "firstName",
        },
        {
          text: "نام خانوادگی",
          align: "start",
          value: "lastName",
        },
        {
          text: "موبایل",
          align: "start",
          value: "userName",
        },
        {
          text: "کد ملی",
          align: "start",
          value: "nationalCode",
        },
        // {
        //   text: "شرکت مادر",
        //   align: "start",
        //   value: "parentCompanyName",
        // },
        {
          text: "شماره تماس",
          align: "start",
          value: "phone",
        },
        {
          text: "ایمیل",
          align: "start",
          value: "email",
        },
        {
          text: "فعال",
          align: "start",
          value: "isActive",
        },

        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "gecompanyList",
      "getPersonList",
      "gecompanyList",
      "getorgpostList",
      "getUrl",
      "getLoading",
    ]),
    pagesCount() {
      return Math.ceil(this.getPersonList.record_count / this.pageSize);
    },
  },
  created() {
    this.getRoles();
    this.getPerson();
    this.getCompany();
    this.getorgpost();
    this.getListProvince();
    this.getListCity();
    this.getUsertypeList();
  },
  methods: {
    getData(item) {
      axios
        .get(`${this.getUrl}/person/get/`, {
          params: {
            Id: item.id,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
          this.editData.data.roleNames = response.data.roleName;
          this.post = [];
          this.editData.data.organPost = [];
          this.editData.data.test = this.$refs;
          this.editData.data.page = this.pageCurent;
          if (this.pageCurent == null) {
            this.editData.data.page = 1;
          }
          this.post = response.data.organPost;
          for (const p of this.post) {
            this.editData.data.organPost.push(p.orgPostId);
          }
          this.getListCityById(this.editData.data.provinceFk);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getRoles() {
      axios
        .get(`${this.getUrl}/person/allrolenames/`)
        .then((response) => {
          if (response.data.status == 1) {
            this.listRoles = response.data.allRoleNames;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPerson() {
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: 1,
        count: this.pageSize,
        phrase: "",
      });
    },
    handlePageChange(page) {
      this.getPage(page);
    },
    searchBoxChanged() {
      this.getPage(1);
    },
    getPage(page) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.page = page;
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: page,
        count: this.pageSize,
        phrase: this.search,
      });
    },

    addItem() {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("sendNewitem", { data: this.addData });
        this.addForm = false;
      }
    },

    openFormEdit(item) {
      this.getData(item);
      this.editForm = true;
      //item.passWord = "";
      //this.editData.data = JSON.parse(JSON.stringify(item));
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        this.$store.dispatch("sendEditItem", {
          data: this.editData,
          id: this.editData.manCompanyId,
        });
        this.editForm = false;
      }
    },

    getCompany() {
      this.$store.dispatch("sendList", {
        url: "company/list/",
        page: 1,
        count: -1,
        Phrase: "",
      });
    },
    getorgpost() {
      this.$store.dispatch("sendList", {
        url: "orgpost/list/",
        page: 1,
        count: -1,
        Phrase: "",
      });
    },
    getUsertypeList() {
      axios
        .get(`${this.getUrl}/enum/usertype/`, {
          params: {
            page: 1,
            count: -1,
            Language: 0,
          },
        })
        .then((response) => {
          this.usertypeList = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getListProvince() {
      axios
        .get(`${this.getUrl}/province/list/`, {
          params: {
            page: 1,
            count: -1,
            Language: 0,
          },
        })
        .then((response) => {
          this.listProvince = response.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getListCity() {
      axios
        .get(`${this.getUrl}/city/list/`, {
          params: {
            page: 1,
            count: -1,
            Language: 0,
          },
        })
        .then((response) => {
          this.listAllCity = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListCityById(item) {
      let result = this.listAllCity.records.filter((city) => {
        return city.parentId == item;
      });
      this.listCity = result;
      console.log(this.listCity);
    },

    updateAddDataBirthDate(date) {
      this.addData.BirthDate = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updateEditBirthDate(date) {
      this.editData.data.BirthDate = moment(date)
        .locale("fa")
        .format("YYYY/MM/DD");
    },

    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "person/delete/";
    },
    deleteItemConfirm() {
      this.$store.dispatch("sendRemoveItem", {
        data: this.removeData,
        id: this.removeData.manCompanyId,
      });
      this.closeDelete();
    },

    closeDelete() {
      this.dialogDelete = false;
    },
    sendShowHelp() {
      this.$emit("ShowHelp", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
