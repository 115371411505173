<template>
  <section class="section-main">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <v-card class="rounded-lg">
            <v-card-title>
              <h4>{{ title }} {{ id }}</h4>
            </v-card-title>
            <v-card-text v-if="project">
              <v-row>
                <v-col cols="10" lg="4">
                  <fieldset class="px-2">
                    <legend>عنوان</legend>
                    {{ project.title }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>تاریخ شروع</legend>
                    {{ toPersianDate(project.startDate) }}
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="4">
                  <fieldset class="px-2">
                    <legend>تاریخ پایان</legend>
                    {{ toPersianDate(project.finishDate) }}
                  </fieldset>
                </v-col>
                <v-col cols="10" lg="4">
                  <fieldset class="px-2">
                    <legend>گروه</legend>
                    {{ project.groupTitle }}
                  </fieldset>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import { mapState } from "vuex";
import { projectMixin } from "../mixins";
import { myMixin } from "../../../../mixins.js";
import MenuLeft from "@/components/MenuLeft.vue";

export default {
  mixins: [myMixin, projectMixin],
  components: {
    MenuLeft,
  },
  props: {
    id: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      title: "جزییات پروژه",
    };
  },
  computed: {
    ...mapState({
      project: (state) => state.projects.item,
      loading: (state) => state.projects.loading,
    }),
  },
  mounted() {
    console.log(this.id);
    this.getProject(Number(this.id));
  },
};
</script>
<style scoped>
fieldset {
  border-radius: 5px; /* Adjust the radius as needed */
  border: 2px solid #ccc; /* Optional: Add a border */
  padding: 10px; /* Optional: Add padding */
}
.active-icon {
  color: green;
}
.inactive-icon {
  color: red;
}
</style>
