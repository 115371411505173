import moment from "jalali-moment";

export const myMixin = {
  data() {
    return {
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
      emailValid: [(v) => !v || /.+@.+/.test(v) || "E-mail must be valid"],
      numberRules: [
        (v) => !!v || "Phone is required",
        (v) => /^[0-9]+$/.test(v) || "E-mail must be valid",
      ],
      numberValid: [
        (v) => (v) => !v || /^[0-9]+$/.test(v) || "E-mail must be valid",
      ],
      nameRules: [(v) => !!v || "الزامی است"],
      requiredRule: [(v) => !!v || "الزامی است"],
      requiredMultiRule: [(v) => !!(v && v.length) || "الزامی است"],
    };
  },
  methods: {
    toPersianDate(date) {
      return moment.from(date, "en").locale("fa").format("jYYYY/jMM/jDD");
    },
    toPersianDateTime(date) {
      return moment.from(date, "en").locale("fa").format("jYYYY/jMM/jDD HH:mm");
    },
    commaSep(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
