export const projectMixin = {
  methods: {
    createProject(data, language = 1) {
      return this.$store.dispatch("CREATE_PROJECT", { language, data });
    },
    updateProject(id, data, language = 1) {
      return this.$store.dispatch("UPDATE_PROJECT", { id, language, data });
    },
    deleteProject(id, language = 1) {
      return this.$store.dispatch("DELETE_PROJECT", { id, language });
    },
    getProject(id, language = 1) {
      return this.$store.dispatch("GET_PROJECT", { id, language });
    },
    getProjects(page = 1, count = -1, phrase = "", customerId = undefined) {
      return this.$store.dispatch("GET_PROJECTS", {
        page,
        count,
        phrase,
        customerId,
      });
    },
    createProjectGroup(data, language = 1) {
      this.$store.dispatch("CREATE_PROJECT_GROUP", { data, language });
    },
    updateProjectGroup(id, data, language = 1) {
      this.$store.dispatch("UPDATE_PROJECT_GROUP", { id, data, language });
    },
    deleteProjectGroup(id, language = 1) {
      this.$store.dispatch("DELETE_PROJECT_GROUP", { id, language });
    },
    getProjectGroups(page = 1, count = -1, phrase = "") {
      this.$store.dispatch("GET_PROJECT_GROUPS", {
        page,
        phrase,
        count,
      });
    },
  },
};
