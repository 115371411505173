<template>
  <section class="section__bankaccounts">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__bankaccounts-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getBankAccountList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="شماره حساب"
                        hide-details
                        outlined
                        dense
                        v-model="addData.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        label="بانک"
                        hide-details
                        outlined
                        :items="getBankList.records"
                        item-text="title"
                        item-value="bankId"
                        dense
                        v-model="addData.bankFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="شماره حساب"
                        hide-details
                        outlined
                        dense
                        v-model="editData.data.number"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        label="بانک"
                        hide-details
                        outlined
                        :items="getBankList.records"
                        item-text="title"
                        item-value="bankId"
                        dense
                        v-model="editData.data.bankFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__bankaccounts-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "BankAccounts",
  mixins: [myMixin],
  data() {
    return {
      searchTest: null,
      dialog: false,
      search: null,
      bankTitle: null,
      addData: {
        url: "bankaccount/create/",
        formName: "formAdd",
        bankFk: null,
        number: null,
        test: this.$refs,
      },
      editData: {
        url: "bankaccount/update/",
        formName: "formEdit",
        data: { BankFk: null, Number: null },
      },
      removeData: { url: "/bankaccount/delete/" },
      headers: [
        {
          text: "بانک ",
          align: "right",
          value: "bankFk",
        },
        {
          text: "شماره حساب",
          align: "center",
          value: "number",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  components: {
    MenuLeft,
    AccountingSystem,
  },
  mounted() {
    this.getBankAccount();
    this.getBanks();
  },
  computed: {
    ...mapGetters(["getBankAccountList", "getBankList", "getUrl"]),
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "bankaccount/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "bankaccount/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "bank/list/",
        page: 1,
        count: -1,
        Phrase: "",
      });
    },
    // getTitleBank(number){
    //   console.log(number , 'number');
    //   console.log(this.getBankList , 'this.getBankList');
    //  let result = this.getBankList.records.find((bank)=>{
    //   return bank.BankId == number;
    //  })
    //     console.log(result);
    // },
    getBankAccount() {
      this.$store.dispatch("sendList", {
        url: "bankaccount/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/bankaccount/get/`, {
          params: {
            Id: item.bankAccountId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response, "==============");
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
          // this.getTitleBank(response.data.record.BankFk);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__bankaccounts {
  background-color: #eeeeee !important;
}
</style>
