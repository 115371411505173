var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"customer-section"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.customer)?_c('v-col',{attrs:{"cols":"12","md":"9","lg":"10"}},[_c('v-card',[_c('v-card-title',[_vm._v(" جزییات مشتری ")]),(_vm.customer)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("شخص / شرکت\"")]),_vm._v(" "+_vm._s(_vm.customer.name)+" ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("کد\"")]),_vm._v(" "+_vm._s(_vm.customer.code)+" ")])]),_c('v-col',{attrs:{"cols":"6","lg":"5"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("شماره تماس")]),_vm._v(" "+_vm._s(_vm.customer.tell)+" ")])]),_c('v-col',{attrs:{"cols":"6","lg":"5"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("شماره همراه")]),_vm._v(" "+_vm._s(_vm.customer.mobile)+" ")])]),_c('v-col',{attrs:{"cols":"6","lg":"2"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("شماره واتساپ")]),_c('v-icon',{class:{
                      'active-icon': _vm.customer.whatsApp,
                      'inactive-icon': !_vm.customer.whatsApp,
                    }},[_vm._v(" "+_vm._s(_vm.customer.whatsApp ? "mdi-check-circle" : "mdi-cancel")+" ")])],1)]),_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("ایمیل")]),_vm._v(" "+_vm._s(_vm.customer.email)+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('fieldset',{staticClass:"px-2"},[_c('legend',[_vm._v("توضیحات")]),_vm._v(" "+_vm._s(_vm.customer.description)+" ")])])],1)],1):_vm._e()],1),_c('v-card',[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
                            name: 'IncomingLetters',
                            query: {
                              fromCustomerId: _vm.customer.id,
                            },
                          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.customer.messageCount)+" نامه از "+_vm._s(_vm.customer.name)+" دریافت شده ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
                            name: 'LettersIssued',
                            query: {
                              toCustomerId: _vm.customer.id,
                            },
                          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.customer.messageReceiverCount)+" نامه به "+_vm._s(_vm.customer.name)+" ارسال شده ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
                            name: 'ContractsList',
                            query: {
                              customerId: _vm.customer.id,
                            },
                          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.customer.contractCount)+" قرارداد به طرفیت "+_vm._s(_vm.customer.name)+" ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
                            name: 'AccountingList',
                            query: {
                              customerId: _vm.customer.id,
                            },
                          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.customer.documentCount)+" سند حسابداری ")])])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('router-link',{attrs:{"to":{
                            name: 'AccountingCategory',
                            query: {
                              customerId: _vm.customer.id,
                            },
                          }}},[_c('span',[_vm._v(" "+_vm._s(_vm.customer.invoicesCount)+" فاکتور ")])])],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),_c('v-col',{staticClass:"section__banks-data px-5",attrs:{"cols":"12","md":"3","lg":"2"}},[_c('MenuLeft')],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }