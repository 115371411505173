import ContractsList from "../components/ContractsList.vue";
import Contract from "../components/Contract.vue";
import ContractGroupsList from "../components/ContractGroupsList.vue";

export const contractRoutes = [
  {
    path: "/contracts/list",
    component: ContractsList,
    name: "ContractsList",
  },
  {
    path: "/contracts/groups",
    component: ContractGroupsList,
    name: "ContractGroupsList",
  },
  {
    path: "/contracts/:id",
    component: Contract,
    name: "Contract",
    props: true,
  },
];
