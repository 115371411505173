<template>
  <section class="section-main">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <v-card class="rounded-lg">
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="addForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="addForm = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="formAdd">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          :rules="requiredRule"
                          label="عنوان قرارداد"
                          outlined
                          dense
                          hide-details
                          v-model="addData.contractTitle"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          label="مبلغ قرارداد"
                          outlined
                          hide-details
                          dense
                          v-model="addData.contractMainPrice"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          label="شماره قرارداد"
                          outlined
                          hide-details
                          dense
                          v-model="addData.contractNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          v-model="addData.contractType"
                          label="نوع قرارداد"
                          hide-details
                          outlined
                          :items="contractType"
                          item-text="title"
                          item-value="value"
                          dense
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-autocomplete
                          v-if="addData.contractType == 1"
                          label="طرف قرارداد"
                          hide-details
                          outlined
                          :items="getPersonList.records"
                          :item-text="
                            (item) => `${item.firstName} ${item.lastName}`
                          "
                          item-value="id"
                          dense
                          v-model="addData.userId"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          v-if="addData.contractType == 2"
                          label="طرف قرارداد"
                          hide-details
                          outlined
                          :items="customers"
                          item-text="name"
                          item-value="customerId"
                          dense
                          v-model="addData.customerFk"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="show = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="addData.startDate"
                              id="my-custom-editable-input"
                              type="text"
                              outlined
                              dense
                              label="تاریخ شروع"
                              @click="show = true"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="addData.startDate"
                              :show="show"
                              :editable="true"
                              :auto-submit="true"
                              custom-input="#my-custom-editable-input"
                              @close="show = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="show1 = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="addData.endDate"
                              id="endDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ اتمام"
                              @click="show1 = true"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="addData.endDate"
                              :show="show1"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#endDate"
                              @close="show1 = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          label="دسته بندی قرارداد"
                          hide-details
                          outlined
                          :items="groupsItems"
                          item-text="contractGroupTitle"
                          item-value="contractGroupId"
                          dense
                          v-model="addData.contractGroupFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          label="توضیح قرارداد"
                          rows="4"
                          row-height="30"
                          v-model="addData.contractNotation"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          label="پروژه"
                          hide-details
                          outlined
                          :items="projects"
                          item-text="projectTitle"
                          item-value="projectId"
                          dense
                          v-model="addData.projectFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox
                          label="فعال"
                          hide-details
                          dense
                          v-model="addData.isActive"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Edit -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="editForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="editForm = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="formEdit">
                  <v-card-text v-if="editData.data">
                    <v-row>
                      <v-col cols="12" lg="8">
                        <v-text-field
                          :rules="requiredRule"
                          label="عنوان قرارداد"
                          outlined
                          dense
                          hide-details
                          v-model="editData.data.contractTitle"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          v-model="editData.data.contractMainPrice"
                          label="مبلغ قرارداد"
                          outlined
                          hide-details
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-text-field
                          label="شماره قرارداد"
                          outlined
                          hide-details
                          dense
                          v-model="editData.data.contractNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          label="نوع قرارداد"
                          hide-details
                          outlined
                          :items="contractType"
                          v-model="editData.data.contractType"
                          item-text="title"
                          item-value="value"
                          dense
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-autocomplete
                          v-if="editData.data.contractType == 1"
                          label="طرف قرارداد"
                          hide-details
                          outlined
                          :items="getPersonList.records"
                          :item-text="
                            (item) => `${item.firstName} ${item.lastName}`
                          "
                          item-value="id"
                          dense
                          v-model="editData.data.userId"
                        >
                        </v-autocomplete>
                        <v-autocomplete
                          v-if="editData.data.contractType == 2"
                          label="طرف قرارداد"
                          hide-details
                          outlined
                          :items="customers"
                          item-text="name"
                          item-value="customerId"
                          dense
                          v-model="editData.data.customerFk"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="show2 = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="editData.data.startDate"
                              id="editData.data.startDate"
                              type="text"
                              outlined
                              dense
                              label="تاریخ شروع"
                              @click="show2 = true"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="editData.data.startDate"
                              :show="show2"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#editData.data.startDate"
                              @close="show2 = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-row>
                          <v-col cols="2"
                            ><v-icon
                              type="button"
                              class="mt-3"
                              @click="show3 = true"
                            >
                              mdi-calendar
                            </v-icon></v-col
                          >
                          <v-col cols="10">
                            <v-text-field
                              v-model="editData.data.endDate"
                              id="editData.data.endDate"
                              type="text"
                              outlined
                              dense
                              @click="show3 = true"
                              label="تاریخ اتمام"
                              :rules="nameRules"
                              readonly
                            ></v-text-field>
                            <date-picker
                              v-model="editData.data.endDate"
                              :show="show3"
                              :auto-submit="true"
                              :editable="true"
                              custom-input="#editData.data.endDate"
                              @close="show3 = false"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          label="دسته بندی قرارداد"
                          hide-details
                          outlined
                          :items="groupsItems"
                          item-text="contractGroupTitle"
                          item-value="contractGroupId"
                          dense
                          v-model="editData.data.contractGroupFk"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          outlined
                          label="توضیح قرارداد"
                          rows="4"
                          row-height="30"
                          v-model="editData.data.contractNotation"
                        ></v-textarea>
                      </v-col>
                      <v-col cols="12" lg="4">
                        <v-select
                          label="پروژه"
                          hide-details
                          outlined
                          :items="projects"
                          item-text="projectTitle"
                          item-value="projectId"
                          dense
                          v-model="editData.data.projectFk"
                        >
                        </v-select>
                      </v-col>
                      <!-- <v-col cols="12" lg="8">
                        <v-combobox
                          v-model="editData.data.contractTags"
                          label="برچسب ها"
                          :search-input.sync="search"
                          multiple
                          outlined
                          dense
                          persistent-hint
                          small-chips
                        >
                          <template v-slot:no-data>
                            <v-list-item>
                              <v-list-item-content>
                                <v-list-item-title>
                                  موردی انتخاب نشده است "<strong>{{
                                    search
                                  }}</strong
                                  >". برای ایجاد دکمه <kbd>enter</kbd> را فشار
                                  دهید
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                        </v-combobox>
                      </v-col> -->
                      <v-col cols="12">
                        <v-checkbox
                          label="فعال"
                          hide-details
                          dense
                          v-model="editData.data.isActive"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Attachment -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="attachmentForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="attachmentForm = false">
                  پیوست
                </v-card-title-close>
                <v-card-text>
                  <v-data-table
                    :headers="headersAttachment"
                    :items="attachmentList"
                    :loading="attachmentLoading"
                  >
                    <template v-slot:[`item.creatorUserId`]="{ item }">
                      <span dense>{{
                        getUserFullName(item.creatorUserId)
                      }}</span>
                    </template>
                    <template v-slot:[`item.createdDate`]="{ item }">
                      <span>{{ toPersianDateTime(item.createdDate) }}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <a
                            v-bind="attrs"
                            v-on="on"
                            :href="
                              getUrl +
                              '/contract/download?id=' +
                              item.contractAttachmentId +
                              '&token=' +
                              token
                            "
                          >
                            <v-icon> mdi-download </v-icon>
                          </a>
                        </template>
                        <span>دانلود</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            small
                            @click="deleteAttachment(item)"
                            color="error"
                          >
                            mdi-delete
                          </v-icon>
                        </template>
                        <span>حذف</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-col cols="6" offset="2">
                    <v-file-input
                      v-model="image"
                      color="deep-purple-accent-4"
                      counter
                      label="انتخاب فایل"
                      placeholder="انتخاب فایل"
                      prepend-icon="mdi-paperclip"
                      variant="outlined"
                      :show-size="1000"
                      v-show="true"
                      ref="fileInput"
                      accept="image/*"
                    ></v-file-input>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      class="mx-1 my-1"
                      compact
                      color="success"
                      v-on:click="uploadAttachment()"
                      :disabled="this.image == null"
                    >
                      <v-icon small>mdi-undo</v-icon>
                      آپلود
                    </v-btn>
                  </v-col>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Payment -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="paymentForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="paymentForm = false">
                  دریافت و پرداخت های قراداد
                </v-card-title-close>
                <v-card-text>
                  <DataTableChild
                    :headers="headersPayment"
                    v-on:sendData="getDataPayment"
                    :sendList="getContractpaymentList.records"
                    :sendaddData="paymentAddData"
                    :sendEditData="paymentEditData"
                    :sendremoveData="paymentRemoveData"
                    :plus="true"
                    :download="false"
                    :edit="true"
                  >
                    <template v-slot:addForm>
                      <form ref="paymentAddForm">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="عنوان"
                                outlined
                                hide-details
                                dense
                                v-model="paymentAddData.guaranteeTitle"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-select
                                label="نوع پرداخت"
                                hide-details
                                outlined
                                :items="paymentType"
                                item-text="value"
                                item-value="id"
                                dense
                                v-model="paymentAddData.paymentType"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="نحوه پرداخت"
                                hide-details
                                outlined
                                :items="paymentMethod"
                                item-text="value"
                                item-value="id"
                                dense
                                v-model="paymentAddData.paymentMethodFk"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="شماره سند"
                                outlined
                                dense
                                hide-details
                                v-model="paymentAddData.vchNumber"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="مبلغ"
                                outlined
                                dense
                                hide-details
                                v-model="paymentAddData.price"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-row>
                                <v-col cols="2"
                                  ><v-icon
                                    type="button"
                                    class="mt-3"
                                    @click="show6 = true"
                                  >
                                    mdi-calendar
                                  </v-icon></v-col
                                >
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="paymentAddData.duedate"
                                    id="paymentAddData.duedate"
                                    type="text"
                                    outlined
                                    dense
                                    label="تاریخ دریافت / تحویل"
                                    readonly
                                    @click="show6 = true"
                                    :rules="nameRules"
                                  ></v-text-field>
                                  <date-picker
                                    v-model="paymentAddData.duedate"
                                    :show="show6"
                                    :auto-submit="true"
                                    :editable="true"
                                    custom-input="#paymentAddData.duedate"
                                    @close="show6 = false"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-row>
                                <v-col cols="2"
                                  ><v-icon
                                    type="button"
                                    class="mt-3"
                                    @click="show7 = true"
                                  >
                                    mdi-calendar
                                  </v-icon></v-col
                                >
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="paymentAddData.deliveryDate"
                                    id="paymentAddData.deliveryDate"
                                    type="text"
                                    outlined
                                    dense
                                    label="تاریخ سررسید"
                                    readonly
                                    @click="show7 = true"
                                    :rules="nameRules"
                                  ></v-text-field>
                                  <date-picker
                                    v-model="paymentAddData.deliveryDate"
                                    :show="show7"
                                    :auto-submit="true"
                                    :editable="true"
                                    custom-input="#paymentAddData.deliveryDate"
                                    @close="show7 = false"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="توضیحات"
                                outlined
                                dense
                                hide-details
                                v-model="paymentAddData.comment"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </form>
                    </template>
                    <template v-slot:editForm>
                      <form ref="paymentEditForm">
                        <v-card-text>
                          <v-row>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="عنوان"
                                outlined
                                hide-details
                                dense
                                v-model="paymentEditData.data.guaranteeTitle"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-select
                                label="نوع پرداخت"
                                hide-details
                                outlined
                                :items="paymentType"
                                item-text="value"
                                item-value="id"
                                dense
                                v-model="paymentEditData.data.paymentType"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12">
                              <v-select
                                label="نحوه پرداخت"
                                hide-details
                                outlined
                                :items="paymentMethod"
                                item-text="value"
                                item-value="id"
                                dense
                                v-model="paymentEditData.data.paymentMethodFk"
                              >
                              </v-select>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="شماره سند"
                                outlined
                                dense
                                hide-details
                                v-model="paymentEditData.data.vchNumber"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-text-field
                                label="مبلغ"
                                outlined
                                dense
                                hide-details
                                v-model="paymentEditData.data.price"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-row>
                                <v-col cols="2"
                                  ><v-icon
                                    type="button"
                                    class="mt-3"
                                    @click="show8 = true"
                                  >
                                    mdi-calendar
                                  </v-icon></v-col
                                >
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="paymentEditData.data.duedate"
                                    id="paymentEditData.duedate"
                                    type="text"
                                    outlined
                                    dense
                                    label="تاریخ دریافت / تحویل"
                                    readonly
                                    :rules="nameRules"
                                    @click="show8 = true"
                                  ></v-text-field>
                                  <date-picker
                                    v-model="paymentEditData.data.duedate"
                                    :show="show8"
                                    :auto-submit="true"
                                    :editable="true"
                                    custom-input="#paymentEditData.duedate"
                                    @close="show8 = false"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12" lg="6">
                              <v-row>
                                <v-col cols="2"
                                  ><v-icon
                                    type="button"
                                    class="mt-3"
                                    @click="show9 = true"
                                  >
                                    mdi-calendar
                                  </v-icon></v-col
                                >
                                <v-col cols="10">
                                  <v-text-field
                                    v-model="paymentEditData.data.deliveryDate"
                                    id="paymentEditData.deliveryDate"
                                    type="text"
                                    outlined
                                    dense
                                    label="تاریخ سررسید"
                                    readonly
                                    :rules="nameRules"
                                    @click="show9 = true"
                                  ></v-text-field>
                                  <date-picker
                                    v-model="paymentEditData.data.deliveryDate"
                                    :show="show9"
                                    :auto-submit="true"
                                    :editable="true"
                                    custom-input="#paymentEditData.deliveryDate"
                                    @close="show9 = false"
                                  />
                                </v-col>
                              </v-row>
                            </v-col>
                            <v-col cols="12">
                              <v-text-field
                                label="توضیحات"
                                outlined
                                dense
                                hide-details
                                v-model="paymentEditData.data.comment"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </form>
                    </template>
                  </DataTableChild>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- Supplement -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="supplementForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="supplementForm = false">
                  متمم های قرارداد
                </v-card-title-close>
                <v-card-text>
                  <DataTableChild
                    :headers="headerssupplement"
                    v-on:sendData="getDatasupplement"
                    :sendList="getContractSupplementList.records"
                    :sendaddData="supplementAddData"
                    :sendEditData="supplementEditData"
                    :sendremoveData="supplementremoveData"
                    :plus="true"
                    :download="false"
                    :edit="true"
                  >
                    <template v-slot:addForm>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              label="نوع متمم"
                              hide-details
                              outlined
                              :items="supplementType.records"
                              item-text="coefficientsTitle"
                              item-value="coefficientsId"
                              dense
                              v-model="supplementAddData.coefficientsFk"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="درصد"
                              outlined
                              dense
                              hide-details
                              v-model="supplementAddData.percentage"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="مبلغ"
                              outlined
                              dense
                              hide-details
                              v-model="supplementAddData.price"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="توضیحات"
                              outlined
                              dense
                              hide-details
                              v-model="supplementAddData.comment"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </template>
                    <template v-slot:editForm>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              label="نوع پرداخت"
                              hide-details
                              outlined
                              :items="supplementType.records"
                              item-text="coefficientsTitle"
                              item-value="coefficientsId"
                              dense
                              v-model="supplementEditData.data.coefficientsFk"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="درصد"
                              outlined
                              dense
                              hide-details
                              v-model="supplementEditData.data.percentage"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" lg="6">
                            <v-text-field
                              label="مبلغ"
                              outlined
                              dense
                              hide-details
                              v-model="supplementEditData.data.price"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              label="توضیحات"
                              outlined
                              dense
                              hide-details
                              v-model="supplementEditData.data.comment"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </template>
                  </DataTableChild>
                </v-card-text>
              </v-card>
            </v-dialog>

            <!-- Action -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="actionForm"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="actionForm = false">
                  فعالیت های قرارداد
                </v-card-title-close>
                <v-card-text>
                  <DataTableChild
                    :headers="headersAction"
                    v-on:sendData="getDataAction"
                    :sendList="getContractActionList.records"
                    :sendaddData="actionAddData"
                    :sendEditData="actionEditData"
                    :sendremoveData="actionremoveData"
                    :plus="true"
                    :download="false"
                    :edit="true"
                  >
                    <template v-slot:addForm>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              label="عنوان فعالیت"
                              hide-details
                              outlined
                              :items="actionType.records"
                              item-text="activityTypeTitle"
                              item-value="activityTypeId"
                              dense
                              v-model="actionAddData.activityTypeFk"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="1"
                                ><v-icon
                                  type="button"
                                  class="mt-3"
                                  @click="show4 = true"
                                >
                                  mdi-calendar
                                </v-icon></v-col
                              >
                              <v-col cols="11">
                                <v-text-field
                                  v-model="actionAddData.activityDate"
                                  id="actionAddData.activityDate"
                                  type="text"
                                  outlined
                                  dense
                                  label="تاریخ"
                                  readonly
                                  :rules="nameRules"
                                  @click="show4 = true"
                                ></v-text-field>
                                <date-picker
                                  v-model="actionAddData.activityDate"
                                  :show="show4"
                                  :auto-submit="true"
                                  :editable="true"
                                  custom-input="#actionAddData.activityDate"
                                  @close="show4 = false"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </template>
                    <template v-slot:editForm>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              label="عنوان فعالیت"
                              hide-details
                              outlined
                              :items="actionType.records"
                              item-text="activityTypeTitle"
                              item-value="activityTypeId"
                              dense
                              v-model="actionEditData.data.activityTypeFk"
                            >
                            </v-select>
                          </v-col>
                          <v-col cols="12">
                            <v-row>
                              <v-col cols="1"
                                ><v-icon
                                  type="button"
                                  class="mt-3"
                                  @click="show5 = true"
                                >
                                  mdi-calendar
                                </v-icon></v-col
                              >
                              <v-col cols="11">
                                <v-text-field
                                  v-model="actionEditData.data.activityDate"
                                  id="actionEditData.data.activityDate"
                                  type="text"
                                  outlined
                                  dense
                                  label="تاریخ"
                                  readonly
                                  :rules="nameRules"
                                  @click="show5 = true"
                                ></v-text-field>
                                <date-picker
                                  v-model="actionEditData.data.activityDate"
                                  :show="show5"
                                  :auto-submit="true"
                                  :editable="true"
                                  custom-input="#actionEditData.data.activityDate"
                                  @close="show5 = false"
                                />
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </template>
                  </DataTableChild>
                </v-card-text>
              </v-card>
            </v-dialog>

            <v-card-actions>
              <v-row>
                <v-col cols="6">
                  <v-card-title>{{ title }}</v-card-title>
                </v-col>
                <v-col cols="6" class="text-end">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        class="ml-3 mt-3"
                        color="warning"
                        dark
                        v-bind="attrs"
                        v-on="on"
                        v-on:click="sendShowHelp"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span>راهنما</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12">
                  <v-btn class="mr-2 success" @click="openAddDialog">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-card-title>
              <v-text-field
                v-model="search"
                outlined
                dense
                append-icon="mdi-magnify"
                label="جستجو"
                @input="searchBoxChanged"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>

            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  :items-per-page="pageSize"
                  :server-items-length="total"
                  :page="pageNo"
                  :fixed-header="true"
                  :loading="loading"
                >
                  <template v-slot:[`item.index`]="{ index }">
                    {{ startIndex + index + 1 }}
                  </template>
                  <template v-slot:[`item.contractNumber`]="{ item }">
                    {{ item.contractNumber }}
                  </template>
                  <template v-slot:[`item.contractTitle`]="{ item }">
                    <router-link
                      :to="{
                        name: 'Contract',
                        params: {
                          id: Number(item.contractId),
                        },
                      }"
                      ><span>{{ item.contractTitle }}</span>
                    </router-link>
                  </template>
                  <template v-slot:[`item.companyId`]="{ item }">
                    <span dense>{{ getUserFullName(item.companyId) }}</span>
                  </template>
                  <template v-slot:[`item.contractMainPrice`]="{ item }">
                    <span dense>{{ commaSep(item.contractMainPrice) }}</span>
                  </template>
                  <template v-slot:[`item.contractType`]="{ item }">
                    <span dense>{{
                      item.contractType == 1
                        ? "داخلی"
                        : item.contractType == 2
                        ? "خارجی"
                        : ""
                    }}</span>
                  </template>
                  <template v-slot:[`item.isActive`]="{ item }">
                    <v-icon
                      :class="{
                        'active-icon': item.isActive,
                        'inactive-icon': !item.isActive,
                      }"
                    >
                      {{ item.isActive ? "mdi-check-circle" : "mdi-cancel" }}
                    </v-icon>
                  </template>
                  <template v-slot:[`item.startDate`]="{ item }">
                    <span>{{ toPersianDate(item.startDate) }}</span>
                  </template>
                  <template v-slot:[`item.endDate`]="{ item }">
                    <span>{{ toPersianDate(item.endDate) }}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openFormAttachment(item)"
                        >
                          mdi-paperclip
                        </v-icon>
                      </template>
                      <span>پیوست</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openPaymentForm(item)"
                        >
                          mdi-cash
                        </v-icon>
                      </template>
                      <span>دریافت و پرداخت</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openSupplementForm(item)"
                        >
                          mdi-code-braces
                        </v-icon>
                      </template>
                      <span>متمم قرارداد</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          @click="openActionForm(item)"
                        >
                          mdi-anchor
                        </v-icon>
                      </template>
                      <span>فعالیت قرارداد</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col cols="12" v-if="pagesCount > 1">
                    <v-pagination
                      :value="pageNo"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../../components/MenuLeft";
import VCardTitleClose from "../../../components/VCardTitleClose";
import DataTableChild from "../../../components/DataTableChild.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import { myMixin } from "../../../../mixins.js";
import { mapGetters, mapState } from "vuex";
import moment from "jalali-moment";
import { contractMixin } from "../mixins";
import { customerMixin } from "@/modules/customers/mixins";
import { projectMixin } from "@/modules/projects/mixins";
import { reactive } from "vue";
export default {
  mixins: [myMixin, customerMixin, contractMixin, projectMixin],
  name: "ContractsList",
  components: {
    VCardTitleClose,
    MenuLeft,
    DataTableChild,
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      search: "",
      dialogDelete: false,
      addForm: false,
      editForm: false,
      attachmentForm: false,
      image: null,
      paymentForm: false,
      supplementForm: false,
      actionForm: false,
      selectedContract: null,
      attachmentLoading: false,
      select: [],
      show: false,
      show1: false,
      show2: false,
      show3: false,
      show4: false,
      show5: false,
      show6: false,
      show7: false,
      show8: false,
      show9: false,
      supplementType: [],
      attachmentList: [],
      addData: {},
      editData: {},
      removeData: { url: "contract/delete/" },
      paymentAddData: {
        url: "contractpayment/create/",
        formName: "paymentAddForm",
        guaranteeTitle: null,
        contractFk: null,
        paymentMethodFk: 0,
        vchNumber: null,
        duedate: null,
        deliveryDate: null,
        price: 0,
        comment: null,
        paymentType: 0,
        contractNotation: null,
      },
      paymentEditData: {
        url: "contractpayment/update/",
        formName: "paymentEditForm",
        data: {
          guaranteeTitle: null,
          contractFk: null,
          paymentMethodFk: null,
          vchNumber: null,
          duedate: null,
          deliveryDate: null,
          price: null,
          comment: null,
          paymentType: null,
          contractNotation: null,
        },
      },
      paymentRemoveData: { url: "contractpayment/delete/" },
      supplementAddData: {
        url: "contractcoefficient/create/",
        price: null,
        percentage: null,
        comment: null,
        coefficientsFk: null,
        contractFk: null,
      },
      supplementEditData: {
        url: "contractcoefficient/update/",
        data: {
          price: null,
          percentage: null,
          comment: null,
          coefficientsFk: null,
          contractFk: null,
        },
      },
      supplementremoveData: { url: "contractcoefficient/delete/" },
      actionAddData: {
        url: "contractactivity/create/",
        activityTypeFk: null,
        activityDate: null,
        contractFk: null,
      },
      actionEditData: {
        url: "contractactivity/update/",
        data: {
          activityTypeFk: null,
          activityDate: null,
          contractFk: null,
        },
      },
      actionremoveData: { url: "contractactivity/delete/" },
      paymentType: [],
      paymentMethod: [],
      contractType: [
        {
          title: "داخلی",
          value: 1,
        },
        {
          title: "خارجی",
          value: 2,
        },
      ],
      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          sortable: false,
        },
        {
          text: "شماره قراداد",
          align: "center",
          value: "contractNumber",
          sortable: false,
        },
        {
          text: "عنوان قراداد",
          align: "start",
          value: "contractTitle",
          sortable: false,
        },
        // {
        //   text: "طرف مقابل",
        //   align: "center",
        //   value: "manCompanyFk",
        // },
        {
          text: "مبلغ قرارداد",
          align: "center",
          value: "contractMainPrice",
          sortable: false,
        },
        {
          text: "نوع قرارداد",
          align: "center",
          value: "contractType",
          sortable: false,
        },
        {
          text: "تاریخ شروع",
          align: "center",
          value: "startDate",
          sortable: false,
        },
        {
          text: "تاریخ پایان",
          align: "center",
          value: "endDate",
          sortable: false,
        },
        {
          text: "فعال",
          align: "center",
          value: "isActive",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
      headersPayment: [
        {
          text: "شماره فیش",
          align: "start",
          value: "vchNumber",
          sortable: false,
        },
        {
          text: "مبلغ",
          align: "start",
          value: "price",
          sortable: false,
        },
        {
          text: "تاریخ سررسید",
          align: "start",
          value: "deliveryDate",
          sortable: false,
        },
        {
          text: "تاریخ اقدام",
          align: "start",
          value: "duedate",
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headerssupplement: [
        {
          text: "عنوان تضامین",
          align: "start",
          value: "contractCoefficientsId",
        },
        {
          text: "درصد",
          align: "start",
          value: "percentage",
        },
        {
          text: "مبلغ",
          align: "start",
          value: "price",
        },
        {
          text: "توضیحات",
          align: "start",
          value: "comment",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headersAction: [
        {
          text: "عنوان فعالیت",
          align: "start",
          value: "activityTypeTitle",
        },
        {
          text: "تاریخ فعالیت",
          align: "start",
          value: "activityDate",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      headersAttachment: [
        {
          text: "عنوان فایل",
          align: "start",
          value: "attachmentFileName",
        },
        {
          text: "ایجاد کننده",
          align: "start",
          value: "creatorUserId",
        },
        {
          text: "تاریخ",
          align: "start",
          value: "createdDate",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      ContractpaymentListTest: {
        records: [
          {
            VchNumber: "test",
            Price: 12000,
            DeliveryDate: "asdasd",
            Duedate: "1402/01/01",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.contracts.items,
      loading: (state) => state.contracts.listLoading,
      pageNo: (state) => state.contracts.pageNo,
      pageSize: (state) => state.contracts.pageSize,
      total: (state) => state.contracts.total,

      groupsItems: (state) => state.contracts.groups.items,
      groupsLoading: (state) => state.contracts.groups.listLoading,

      customers: (state) => state.customers.items,

      projects: (state) => state.projects.items,

      refreshList: (state) => state.contracts.refreshList,
    }),
    ...mapGetters([
      "getContractpaymentList",
      "getContractSupplementList",
      "getContractActionList",
      "getPersonList",
      "getUrl",
      "getCustomerName",
    ]),
    customerName() {
      return this.getCustomerName(this.$route.query.customerId);
    },
    title() {
      return this.customerName
        ? `لیست قرارداد های  ${this.customerName}`
        : "لیست قرارداد ها";
    },
    pagesCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    startIndex() {
      return (this.pageNo - 1) * this.pageSize;
    },
  },
  created() {
    this.getContractsupplement();
    this.getCoefficientType();
    this.getActionType();
    this.getContractAction();
    this.getPaymentType();
    this.getPaymentmethods();
    this.getPerson();

    this.getProjects();
    this.getCustomers();
    this.getContracts(1, 10, "", this.$route.query.customerId);
    this.getContractGroups();
  },
  watch: {
    refreshList() {
      this.getContracts(
        this.pageNo,
        this.pageSize,
        this.query,
        this.$route.query.customerId
      );
    },
  },
  methods: {
    handlePageChange(pageNo) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getContracts(
        pageNo,
        this.pageSize,
        this.search,
        this.$route.query.customerId
      );
    },
    searchBoxChanged() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getContracts(
        1,
        this.pageSize,
        this.search,
        this.$route.query.customerId
      );
    },
    getContractsupplement() {
      this.$store.dispatch("sendListContractSupplement", {
        url: "contractcoefficient/list/",
        id: 34,
      });
    },
    getContractAction() {
      this.$store.dispatch("sendListContractAction", {
        url: "contractactivity/list/",
        id: 34,
      });
    },
    getCoefficientType() {
      axios
        .get(`${this.getUrl}/enum/coefficient/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          this.supplementType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getActionType() {
      axios
        .get(`${this.getUrl}/enum/activitytype/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          this.actionType = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getData(item) {
      this.getContract(item.contractId)
        .then((response) => {
          this.select = [];
          this.editData.data = response.data.record;
          this.paymentAddData.ContractFk = item.contractId;
          this.supplementAddData.ContractFk = item.contractId;
          this.actionAddData.contractFk = item.contractId;
          this.select = response.data.tags;
          this.editData.data.test = this.$refs;
          this.editData.data.contractTags = [];
          for (const item of this.select) {
            this.editData.data.contractTags.push(item.phrase);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPaymentType() {
      axios
        .get(`${this.getUrl}/enum/paymenttypes/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          this.paymentType = response.data.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getPaymentmethods() {
      axios
        .get(`${this.getUrl}/enum/paymentmethods/`, {
          params: {
            Language: 0,
          },
        })
        .then((response) => {
          this.paymentMethod = response.data.records;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDatasupplement(item) {
      axios
        .get(`${this.getUrl}/contractcoefficient/get/`, {
          params: {
            Id: item.contractCoefficientsId,
            ContractFk: item.contractFk,
            Language: 0,
          },
        })
        .then((response) => {
          this.supplementEditData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataPayment(item) {
      axios
        .get(`${this.getUrl}/contractpayment/get/`, {
          params: {
            Id: item.contractPaymentId,
            ContractFk: item.contractFk,
            Language: 0,
          },
        })
        .then((response) => {
          this.paymentEditData.data = response.data.record;
          this.paymentEditData.data.duedate = this.toPersianDate(
            this.paymentEditData.data.duedate
          );
          this.paymentEditData.data.deliveryDate = this.toPersianDate(
            this.paymentEditData.data.deliveryDate
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataAction(item) {
      axios
        .get(`${this.getUrl}/contractactivity/get/`, {
          params: {
            Id: item.contractActivityId,
            ContractFk: item.contractFk,
            Language: 0,
          },
        })
        .then((response) => {
          this.actionEditData.data = response.data.record;
          this.actionEditData.data.activityDate = this.toPersianDate(
            this.actionEditData.data.activityDate
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updatePaymentAddStartDate(date) {
      // this.formattedDate = moment(this.editData.data.StartDate, 'YYYY/MM/DD').format('jYYYY/jM/jD');
      this.addData.startDate = moment(date).locale("fa").format("YYYY/MM/DD");
      // console.log(moment(date).locale("fa").format("jYYYY/jMM/jDD"), "ffffff");
    },
    updatePaymentEditStartDate(date) {
      this.addData.endDate = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updatePaymentAddEndDate(/*date*/) {
      // this.editData.data.StartDate = moment(date).locale('fa').format('YYYY-M-D');
    },
    updatePaymentEditEndDate(/*date*/) {
      // this.editData.data.StartDate = moment(date).locale('fa').format('YYYY-M-D');
    },
    openPaymentForm(item) {
      this.paymentAddData.contractFk = item.contractId;
      this.$store.dispatch("sendListContractPayment", {
        url: "contractpayment/list/",
        id: item.contractId,
        phrase: "",
      });
      this.paymentForm = true;
    },
    updateStartDate(date) {
      this.addData.startDate = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updateAddDataStartDate(date) {
      this.addData.startDate = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updateEditDataStartDate(date) {
      this.editData.data.startDate = moment(date)
        .locale("fa")
        .format("YYYY/MM/DD");
    },
    updateAddDataEndDate(date) {
      this.addData.endDate = moment(date).locale("fa").format("YYYY/MM/DD");
    },
    updateEditDataEndDate(date) {
      this.editData.data.endDate = moment(date)
        .locale("fa")
        .format("YYYY/MM/DD");
    },

    openAddDialog() {
      this.addData = reactive({
        contractGroupFk: null,
        contractNotation: null,
        contractNumber: null,
        contractTitle: null,
        endDate: null,
        isActive: true,
        startDate: null,
        projectFk: null,
        contractTags: [],
        contractType: 1,
        userId: null,
        customerFk: null,
        contractMainPrice: 0,
      });
      this.addForm = true;
    },
    addItem() {
      if (this.$refs.formAdd.validate()) {
        let addData = {
          ...this.addData,
          startDate: moment
            .from(this.addData.startDate, "fa", "YYYY/MM/DD")
            .locale("en")
            .format("YYYY-MM-DDThh:mm:ss"),
          endDate: moment
            .from(this.addData.endDate, "fa", "YYYY/MM/DD")
            .locale("en")
            .format("YYYY-MM-DDThh:mm:ss"),
        };
        this.createContract(addData);
        this.addForm = false;
      } else {
        console.log(this.$refs.formAdd);
      }
    },

    openFormEdit(item) {
      this.editForm = true;
      this.editData = reactive({
        data: {
          ...item,
          endDate: this.toPersianDate(item.endDate),
          isActive: true,
          startDate: this.toPersianDate(item.startDate),
        },
      });
    },
    editItem() {
      if (this.$refs.formEdit.validate()) {
        let editData = {
          ...this.editData.data,
          startDate: moment
            .from(this.editData.data.startDate, "fa", "YYYY/MM/DD")
            .locale("en")
            .format("YYYY-MM-DDThh:mm:ss"),
          endDate: moment
            .from(this.editData.data.endDate, "fa", "YYYY/MM/DD")
            .locale("en")
            .format("YYYY-MM-DDThh:mm:ss"),
        };
        this.updateContract(this.editData.data.contractId, editData);
        this.editForm = false;
      }
    },
    deleteItem(item) {
      this.dialogDelete = true;
      this.removeData = item;
      this.removeData.url = "contract/delete/";
    },
    deleteItemConfirm() {
      if (this.removeData.url == "contract/delete/") {
        this.deleteContract(this.removeData.contractId);
        this.closeDelete();
      } else if (this.removeData.url == "contract/deleteuploadfile/") {
        let data = {
          Id: this.removeData.contractAttachmentId,
          Language: 0,
        };
        axios
          .delete(`${this.getUrl}/contract/deleteuploadfile/`, {
            data: data,
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.closeDelete();
              this.getDataAttachment(this.selectedContract.contractId);
            } else if (data.data.status == 0) {
              this.$store.commit("setMessage", {
                message: data.data.message,
                color: "red",
                timeOut: 2000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    openSupplementForm(item) {
      this.supplementAddData.contractFk = item.contractId;
      this.supplementForm = true;
      this.$emit("sendData", item);
      this.$store.dispatch("sendListContractSupplement", {
        url: "contractcoefficient/list/",
        id: item.contractId,
      });
    },
    openActionForm(item) {
      this.actionAddData.contractFk = item.contractId;
      this.actionForm = true;
      this.$emit("sendData", item);
      this.$store.dispatch("sendListContractAction", {
        url: "contractactivity/list/",
        id: item.contractId,
      });
    },
    sendShowHelp() {
      this.$emit("ShowHelp", true);
    },
    getUserFullName(id) {
      let result = this.getPersonList.records.find((item) => {
        return item.id == id;
      });
      if (result) return `${result.firstName} ${result.lastName}`;
    },
    getPerson() {
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: 1,
        count: -1,
      });
    },

    // attachments
    getDataRemoveAttachment(item) {
      console.log(item);
      axios
        .delete(`${this.getUrl}/contract/deleteuploadfile/`, {
          Id: item.contractAttachmentId,
          Language: 0,
        })
        .then((/*response*/) => {
          this.getData(item);
          // console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getDataAttachment(contractFk) {
      this.attachmentList = [];
      this.attachmentLoading = true;
      axios
        .get(`${this.getUrl}/contract/attachmentlist/`, {
          params: {
            Count: 1000,
            Page: 1,
            Phrase: "",
            ContractFk: contractFk,
            Language: 0,
          },
        })
        .then((data) => {
          this.attachmentList = data.data.records;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.attachmentLoading = false;
        });
    },
    openFormAttachment(item) {
      this.image = null;
      this.selectedContract = item;
      this.getDataAttachment(this.selectedContract.contractId);
      this.attachmentForm = true;
    },
    uploadAttachment() {
      axios
        .post(
          `${this.getUrl}/contract/uploadfile/`,
          {
            Language: 0,
            ContractFk: this.selectedContract.contractId,
            Attachment: this.image,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 1) {
            this.getDataAttachment(this.selectedContract.contractId);
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.image = null;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
          console.log(error);
        });
    },
    deleteAttachment(item) {
      this.removeData = item;
      this.removeData.url = "contract/deleteuploadfile/";
      this.dialogDelete = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
.active-icon {
  color: green;
}
.inactive-icon {
  color: red;
}
</style>
