export const customerMixin = {
  methods: {
    createCustomer(data, language = 1) {
      return this.$store.dispatch("CREATE_CUSTOMER", { language, data });
    },
    updateCustomer(id, data, language = 1) {
      return this.$store.dispatch("UPDATE_CUSTOMER", { id, language, data });
    },
    deleteCustomer(id, language = 1) {
      return this.$store.dispatch("DELETE_CUSTOMER", { id, language });
    },
    getCustomer(id, language = 1) {
      return this.$store.dispatch("GET_CUSTOMER", { id, language });
    },
    getCustomers(page = 1, count = -1, phrase = "") {
      return this.$store.dispatch("GET_CUSTOMERS", {
        page,
        count,
        phrase,
      });
    },
    createCustomerGroup(data, language = 1) {
      this.$store.dispatch("CREATE_CUSTOMER_GROUP", { data, language });
    },
    updateCustomerGroup(id, data, language = 1) {
      this.$store.dispatch("UPDATE_CUSTOMER_GROUP", { id, data, language });
    },
    deleteCustomerGroup(id, language = 1) {
      this.$store.dispatch("DELETE_CUSTOMER_GROUP", { id, language });
    },
    getCustomerGroups(page = 1, count = -1, phrase = "") {
      this.$store.dispatch("GET_CUSTOMER_GROUPS", {
        page,
        phrase,
        count,
      });
    },
  },
};
