<template>
  <section class="customer-section">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" v-if="customer">
          <v-card>
            <v-card-title> جزییات مشتری </v-card-title>
            <v-card-text v-if="customer">
              <v-row>
                <v-col cols="6">
                  <fieldset class="px-2">
                    <legend>شخص / شرکت"</legend>
                    {{ customer.name }}
                  </fieldset>
                </v-col>
                <v-col cols="6">
                  <fieldset class="px-2">
                    <legend>کد"</legend>
                    {{ customer.code }}
                  </fieldset>
                </v-col>
                <v-col cols="6" lg="5">
                  <fieldset class="px-2">
                    <legend>شماره تماس</legend>
                    {{ customer.tell }}
                  </fieldset>
                </v-col>
                <v-col cols="6" lg="5">
                  <fieldset class="px-2">
                    <legend>شماره همراه</legend>
                    {{ customer.mobile }}
                  </fieldset>
                </v-col>
                <v-col cols="6" lg="2">
                  <fieldset class="px-2">
                    <legend>شماره واتساپ</legend>
                    <v-icon
                      :class="{
                        'active-icon': customer.whatsApp,
                        'inactive-icon': !customer.whatsApp,
                      }"
                    >
                      {{
                        customer.whatsApp ? "mdi-check-circle" : "mdi-cancel"
                      }}
                    </v-icon>
                  </fieldset>
                </v-col>
                <v-col cols="12" lg="5">
                  <fieldset class="px-2">
                    <legend>ایمیل</legend>
                    {{ customer.email }}
                  </fieldset>
                </v-col>
                <v-col cols="12">
                  <fieldset class="px-2">
                    <legend>توضیحات</legend>
                    {{ customer.description }}
                  </fieldset>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <v-container>
                <!-- Header -->
                <v-row>
                  <v-col cols="12">
                    <v-card class="pa-4">
                      <v-row>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'IncomingLetters',
                              query: {
                                fromCustomerId: customer.id,
                              },
                            }"
                            ><span>
                              {{ customer.messageCount }}
                              نامه از
                              {{ customer.name }}
                              دریافت شده
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'LettersIssued',
                              query: {
                                toCustomerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.messageReceiverCount }}
                              نامه به
                              {{ customer.name }}
                              ارسال شده
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'ContractsList',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.contractCount }}
                              قرارداد به طرفیت
                              {{ customer.name }}
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AccountingList',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.documentCount }}
                              سند حسابداری
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AccountingCategory',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.invoicesCount }}
                              فاکتور
                            </span>
                          </router-link>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-data px-5">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapState } from "vuex";
import MenuLeft from "@/components/MenuLeft.vue";
// import VTable from "vuetify/lib/components/VDataTable/VDataTable";

export default {
  components: {
    // VTable,
    MenuLeft,
  },
  props: {
    id: {
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("GET_CUSTOMER", {
      id: this.id,
      Language: 0,
    });
  },
  methods: {},
  computed: {
    ...mapState({
      customer: (state) => state.customers.item,
      loading: (state) => state.customers.loading,
    }),
  },
};
</script>

<style scoped>
.customer-section {
  background-color: #eeeeee !important;
}
.customer-desc {
  white-space: pre-wrap;
}
.pa-3 {
  padding: 12px;
}
.pa-4 {
  padding: 16px;
}
.text-h6 {
  font-size: 20px;
}
</style>
<style scoped>
fieldset {
  border-radius: 5px; /* Adjust the radius as needed */
  border: 2px solid darkblue; /* Optional: Add a border */
  padding: 10px; /* Optional: Add padding */
}
.active-icon {
  color: green;
}
.inactive-icon {
  color: red;
}
</style>
