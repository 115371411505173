export default {
  loading: false,
  id: undefined,
  item: undefined,

  listLoading: false,
  items: [],

  pageNo: 1,
  pageSize: 10,
  total: 10,

  refreshList: false,

  groups: {
    refreshList: false,
    loading: false,
    id: undefined,
    item: undefined,
    listLoading: false,
    items: [],
    pageNo: 1,
    pageSize: 10,
    total: 10,
  },
};
