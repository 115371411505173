import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home/Home.vue";

import MyMessage from "../views/LettersManagment/MyMessage.vue";
import UnReadMessage from "../views/LettersManagment/UnReadMessage.vue";
import AnswerRequest from "../views/LettersManagment/AnswerRequest.vue";
import DomesticLetters from "../views/LettersManagment/DomesticLetters.vue";
import IncomingLetters from "../views/LettersManagment/IncomingLetters.vue";
import LettersIssued from "../views/LettersManagment/LettersIssued.vue";
import AllMessage from "../views/LettersManagment/AllMessage.vue";
import ArchiveMessage from "../views/LettersManagment/ArchiveMessage.vue";
import FavoriteMessage from "../views/LettersManagment/FavoriteMessage.vue";
import DraftMessage from "../views/LettersManagment/DraftMessage.vue";
import Letter from "../views/LettersManagment/Letter.vue";
import Banks from "../views/Accounting/Banks.vue";
import Headlines from "../views/Accounting/Headlines.vue";
import BankAccounts from "../views/Accounting/BankAccounts.vue";
import Funds from "../views/Accounting/Funds.vue";
import Andikator from "../views/BaseAutomation/Andikator.vue";
import Archive from "../views/BaseAutomation/Archive.vue";
import Favorite from "../views/BaseAutomation/Favorite.vue";
import OrganizationalPosition from "../views/BaseData/OrganizationalPosition.vue";
import OrganizationalUnit from "../views/BaseData/OrganizationalUnit.vue";
import Company from "../views/BaseData/Company.vue";
import Person from "../views/BaseData/Person.vue";
import ContentList from "../views/ContentManagement/ContentList.vue";
import ContentCategory from "../views/ContentManagement/ContentCategory.vue";
import TemplateManager from "../views/BaseAutomation/TemplateManager.vue";
import AccountingList from "../views/AccountingManagement/AccountingList.vue";
import AccountingCategory from "../views/AccountingManagement/AccountingCategory.vue";
import TicketList from "../views/TicketManagement/TicketList.vue";
import Login from "../views/Login&Register/Login.vue";
import Register from "../views/Login&Register/Register.vue";
import Domains from "../views/Access/Domains.vue";
import Plans from "../views/Access/Plans.vue";
import OnlineUser from "../views/Access/OnlineUser.vue";
import UserGroups from "../views/Access/UserGroups.vue";
import MembersUser from "../views/Access/MembersUser.vue";
import ExclusiveAccess from "../views/Access/ExclusiveAccess.vue";
import UserAccess from "../views/Access/UserAccess.vue";
import Links from "../views/LinkManagement/Links.vue";
import ECE from "../views/ECEManagment/ECE.vue";
import ECEEmail from "../views/ECEManagment/ECEEmail.vue";
import ECEReceivers from "../views/ECEManagment/ECEReceivers.vue";
import VueCookies from "vue-cookies";

//latelier
import Project from "../latelier/imports/ui/projects/TasksList.vue";

import { customerRoutes } from "../modules/customers/router/index.js";
import { contractRoutes } from "@/modules/contracts/router";
import { projectRoutes } from "@/modules/projects/router";

Vue.use(VueRouter);
Vue.use(VueCookies);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    // beforeEnter: (to, from, next) => {
    //   if (Vue.$cookies.get('token')) {
    //     next()
    //   } else {
    //     next({name:'Login'})
    //   }
    // }
  },
  {
    path: "/links",
    component: Links,
    name: "Links",
  },
  {
    path: "/ece",
    component: ECE,
    name: "ECE",
  },
  {
    path: "/ecereceivers",
    component: ECEReceivers,
    name: "ECEReceivers",
  },
  {
    path: "/eceemail",
    component: ECEEmail,
    name: "ECEEmail",
  },
  {
    path: "/mymessage",
    component: MyMessage,
    name: "MyMessage",
  },
  {
    path: "/unreadmessage",
    component: UnReadMessage,
    name: "UnReadMessage",
  },
  {
    path: "/answerrequest",
    component: AnswerRequest,
    name: "AnswerRequest",
  },
  {
    path: "/domesticletters",
    component: DomesticLetters,
    name: "DomesticLetters",
  },
  {
    path: "/incomingletters",
    component: IncomingLetters,
    name: "IncomingLetters",
  },
  {
    path: "/lettersissued",
    component: LettersIssued,
    name: "LettersIssued",
  },
  {
    path: "/allmessage",
    component: AllMessage,
    name: "AllMessage",
    props: true,
  },
  {
    path: "/archivemessage/:typeNumber",
    component: ArchiveMessage,
    name: "ArchiveMessage",
    props: true,
  },
  {
    path: "/favoritemessage/:typeNumber",
    component: FavoriteMessage,
    name: "FavoriteMessage",
    props: true,
  },

  {
    path: "/draftmessage",
    component: DraftMessage,
    name: "DraftMessage",
  },
  {
    path: "/letter/:create/:pagetype/:messagetype/:id",
    component: Letter,
    name: "Letter",
    props: true,
  },
  {
    path: "/banks",
    component: Banks,
    name: "Banks",
  },
  {
    path: "/headlines",
    component: Headlines,
    name: "Headlines",
  },
  {
    path: "/bankaccounts",
    component: BankAccounts,
    name: "BankAccounts",
  },
  {
    path: "/funds",
    component: Funds,
    name: "Funds",
  },
  {
    path: "/andikator",
    component: Andikator,
    name: "Andikator",
  },
  {
    path: "/archive",
    component: Archive,
    name: "Archive",
  },
  {
    path: "/favorite",
    component: Favorite,
    name: "Favorite",
  },
  {
    path: "/templateManager",
    component: TemplateManager,
    name: "TemplateManager",
  },
  {
    path: "/organizationalposition",
    component: OrganizationalPosition,
    name: "OrganizationalPosition",
  },
  {
    path: "/organizationalunit",
    component: OrganizationalUnit,
    name: "OrganizationalUnit",
  },
  {
    path: "/company",
    component: Company,
    name: "Company",
  },
  {
    path: "/person",
    component: Person,
    name: "Person",
  },
  {
    path: "/contentlist",
    component: ContentList,
    name: "ContentList",
  },
  {
    path: "/contentcategory",
    component: ContentCategory,
    name: "ContentCategory",
  },
  {
    path: "/accountinglist",
    component: AccountingList,
    name: "AccountingList",
  },
  {
    path: "/accountingcategory",
    component: AccountingCategory,
    name: "AccountingCategory",
  },
  {
    path: "/ticketlist",
    component: TicketList,
    name: "TicketList",
  },
  {
    path: "/domains",
    component: Domains,
    name: "Domains",
  },
  {
    path: "/plans",
    component: Plans,
    name: "Plans",
  },
  {
    path: "/onlineuser",
    component: OnlineUser,
    name: "OnlineUser",
  },
  {
    path: "/usergroups",
    component: UserGroups,
    name: "UserGroups",
  },
  {
    path: "/membersuser",
    component: MembersUser,
    name: "MembersUser",
  },
  {
    path: "/useraccess",
    component: UserAccess,
    name: "UserAccess",
  },
  {
    path: "/exclusiveaccess",
    component: ExclusiveAccess,
    name: "ExclusiveAccess",
  },
  {
    path: "/login",
    component: Login,
    name: "Login",
  },
  {
    path: "/registerdomain",
    component: Register,
    name: "Register",
  },
  ...customerRoutes,
  ...contractRoutes,
  ...projectRoutes,
  //////////////////
  //latelier
  {
    //path: "/projects/:projectId",
    //name: "project",
    path: "/tasks",
    name: "Tasks",
    //beforeEnter: multiguard([isBasicAuth, projectAuth]),
    component: Project,
    props: true,
    meta: {
      isProject: true,
    },
  },
  {
    path: "/tasks/:projectId(\\d+)/:taskId(\\d+)",
    name: "project-task",
    //beforeEnter: multiguard([isBasicAuth, projectAuth]),
    component: Project,
    props: true,
    meta: {
      isProject: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.name === "Register") {
    next();
  } else if (to.name !== "Login" && !localStorage.getItem("token")) {
    next({ name: "Login" });
  } else {
    next();
  }
});
export default router;
