<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="px-5">
          <v-card>
            <v-dialog v-model="showDeleteDialog" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue-darken-1"
                    variant="text"
                    @click="closeDelete"
                    >لغو</v-btn
                  >
                  <v-btn color="error" variant="text" @click="deleteItemConfirm"
                    >بله</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Add -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="showAddDialog"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="showAddDialog = false">
                  ایجاد
                </v-card-title-close>
                <v-form ref="addForm">
                  <v-card-text>
                    <v-text-field
                      label="عنوان گروه های پروژه"
                      outlined
                      dense
                      v-model="addData.projectGroupTitle"
                    ></v-text-field>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <!-- Edit -->
            <v-dialog
              transition="dialog-bottom-transition"
              max-width="800"
              v-model="showEditDialog"
            >
              <v-card class="rounded-lg">
                <v-card-title-close @close="showEditDialog = false">
                  ویرایش
                </v-card-title-close>
                <v-form ref="editForm">
                  <v-card-text>
                    <v-text-field
                      label="عنوان گروه های پروژه"
                      outlined
                      dense
                      v-model="editData.projectGroupTitle"
                    ></v-text-field>
                  </v-card-text>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-card-title>
              {{ title }}
            </v-card-title>
            <v-card-subtitle>
              <v-row>
                <v-col cols="12" md="2">
                  <v-btn class="mr-2 success" @click="openAddDialog">
                    <v-icon small>mdi-plus</v-icon>
                    افزودن
                  </v-btn>
                </v-col>
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="search"
                    outlined
                    dense
                    append-icon="mdi-magnify"
                    label="جستجو"
                    @input="searchBoxChanged"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-subtitle>
            <v-card-text>
              <div>
                <v-data-table
                  :headers="headers"
                  :items="items"
                  hide-default-footer
                  :items-per-page="pageSize"
                  :server-items-length="total"
                  :page="pageNo"
                  :fixed-header="true"
                  :loading="loading"
                >
                  <template v-slot:[`item.index`]="{ index }">
                    {{ startIndex + index + 1 }}
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          dense
                          class="ma-1"
                          v-bind="attrs"
                          v-on="on"
                          @click="openFormEdit(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>ویرایش</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          dense
                          class="ma-1"
                          color="error"
                          @click="deleteItem(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>حذف</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
                <div class="text-center pt-2"></div>
                <v-row>
                  <v-col cols="12" v-if="pagesCount > 1">
                    <v-pagination
                      :value="pageNo"
                      :length="pagesCount"
                      @input="handlePageChange"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
import MenuLeft from "../../../components/MenuLeft.vue";
import VCardTitleClose from "../../../components/VCardTitleClose";
import { myMixin } from "../../../../mixins.js";
import { projectMixin } from "../mixins";
import { mapState } from "vuex";
export default {
  mixins: [myMixin, projectMixin],
  name: "ProjectGroupsList",
  components: {
    VCardTitleClose,
    MenuLeft,
  },
  data() {
    return {
      title: "گروه های پروژه",
      search: "",
      showDeleteDialog: false,
      showAddDialog: false,
      showEditDialog: false,
      addData: {},
      editData: {
      },
      removeData: { url: "projectgroup/delete/" },
      headers: [
        {
          text: "#",
          align: "start",
          value: "index",
          sortable: false,
        },
        {
          text: "شناسه پروژه",
          align: "start",
          value: "projectGroupId",
          sortable: false,
        },
        {
          text: "عنوان گروه های پروژه",
          align: "start",
          value: "projectGroupTitle",
          sortable: false,
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.projects.groups.items,
      loading: (state) => state.projects.groups.listLoading,
      pageNo: (state) => state.projects.groups.pageNo,
      pageSize: (state) => state.projects.groups.pageSize,
      total: (state) => state.projects.groups.total,

      item: (state) => state.projects.groups.item,
      
      refreshList: (state) => state.projects.groups.refreshList,
    }),
    pagesCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    startIndex() {
      return (this.pageNo - 1) * this.pageSize;
    },
  },
  created() {
    this.getProjectGroups(1, 10, "");
  },
  methods: {
    searchBoxChanged(search) {
      this.getProjectGroups(1, this.pageSize, search);
    },
    handlePageChange(pageNo) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.getProjectGroups(pageNo, this.pageSize, this.search);
    },
    getData(item) {
      this.getProjectGroup(item.projectGroupId)
        .then((response) => {
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openAddDialog() {
      this.showAddDialog = true;
      this.addData = {
        projectGroupTitle: null,
      };
    },
    addItem() {
      if (this.$refs.addForm.validate()) {
        this.createProjectGroup({
          title: this.addData.projectGroupTitle,
        });
        this.showAddDialog = false;
      }
    },
    openFormEdit(item) {
      this.showEditDialog = true;
      this.editData = {...item};
    },
    editItem() {
      if (this.$refs.editForm.validate()) {
        this.updateProjectGroup(this.editData.projectGroupId, {
          title: this.editData.projectGroupTitle,
        });
        this.showEditDialog = false;
      }
    },
    deleteItem(item) {
      this.showDeleteDialog = true;
      this.removeData = item;
    },
    deleteItemConfirm() {
      this.deleteProjectGroup(this.removeData.projectGroupId);
      this.closeDelete();
    },
    closeDelete() {
      this.showDeleteDialog = false;
    },
  },
  watch: {
    refreshList: function () {
      this.getProjectGroups(this.pageNo, this.pageSize, this.search);
    },
  },
};
</script>
<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
