import ProjectsList from "../components/ProjectsList.vue";
import Project from "../components/Project.vue";
import ProjectGroupsList from "../components/ProjectGroupsList.vue";

export const projectRoutes = [
  {
    path: "/projects/list",
    component: ProjectsList,
    name: "ProjectsList",
  },
  {
    path: "/projects/groups",
    component: ProjectGroupsList,
    name: "ProjectGroupsList",
  },
  {
    path: "/projects/:id",
    component: Project,
    name: "Project",
    props: true,
  },
];
