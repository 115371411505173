import axios from "axios";

export default {
  CREATE_CUSTOMER(context, { data }) {
    context.commit("CUSTOMERS__UPDATE_LOADING", true);
    axios
      .post(`${context.getters.getUrl}/v1/Customers`, data)
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_LOADING", false);
      });
  },
  UPDATE_CUSTOMER(context, { id, data }) {
    context.commit("CUSTOMERS__UPDATE_LOADING", true);
    axios
      .put(`${context.getters.getUrl}/v1/Customers/${id}`, data)
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_LOADING", false);
      });
  },
  DELETE_CUSTOMER(context, { id, language = 0 }) {
    context.commit("CUSTOMERS__UPDATE_LOADING", true);
    context.commit("CUSTOMERS__UPDATE_ITEM", undefined);
    axios
      .delete(`${context.getters.getUrl}/v1/Customers/${id}`, {
        params: {
          language,
        },
      })
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_LOADING", false);
      });
  },
  async GET_CUSTOMER(context, { id, language = 0 }) {
    context.commit("CUSTOMERS__UPDATE_LOADING", true);
    context.commit("CUSTOMERS__UPDATE_ITEM", undefined);
    try {
      let response = await axios.get(
        `${context.getters.getUrl}/v1/Customers/${id}`,
        {
          params: {
            language,
          },
        }
      );
      context.commit("CUSTOMERS__UPDATE_ITEM", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      context.commit("CUSTOMERS__UPDATE_LOADING", false);
    }
  },
  GET_CUSTOMERS(context, payload) {
    context.commit("CUSTOMERS__UPDATE_LIST_LOADING", true);
    context.commit("CUSTOMERS__UPDATE_PAGE_NO", payload.page);
    context.commit("CUSTOMERS__UPDATE_PAGE_SIZE", payload.count);
    axios
      .get(`${context.getters.getUrl}/v1/Customers`, {
        params: {
          page: payload.page,
          count: payload.count,
          language: payload.language,
          phrase: payload.phrase,
        },
      })
      .then((response) => {
        context.commit("CUSTOMERS__UPDATE_LIST", response.data.items);
        context.commit("CUSTOMERS__UPDATE_TOTAL", response.data.count);
      })
      .catch((error) => {
        context.state.items = [];
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_LIST_LOADING", false);
      });
  },
  async GET_CUSTOMER_GROUP(context, { id, language = 0 }) {
    context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", true);
    try {
      let response = await axios.get(
        `${context.getters.getUrl}/v1/CustomerGroups/${id}`,
        {
          params: {
            language,
          },
        }
      );
      context.commit("CUSTOMERS__UPDATE_ITEM", response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    } finally {
      context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", false);
    }
  },
  CREATE_CUSTOMER_GROUP(context, { data }) {
    context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", true);
    axios
      .post(`${context.getters.getUrl}/v1/CustomerGroups`, data)
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_GROUPS_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", false);
      });
  },
  UPDATE_CUSTOMER_GROUP(context, { id, data }) {
    context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", true);
    axios
      .put(`${context.getters.getUrl}/v1/CustomerGroups/${id}`, data)
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_GROUPS_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", false);
      });
  },
  DELETE_CUSTOMER_GROUP(context, { id }) {
    context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", true);
    axios
      .delete(`${context.getters.getUrl}/v1/CustomerGroups/${id}`)
      .then((response) => {
        context.commit("CUSTOMERS__REFRESH_GROUPS_LIST");
        context.dispatch("DISPLAY_MESSAGE", response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LOADING", false);
      });
  },
  GET_CUSTOMER_GROUPS(context, payload) {
    context.commit("CUSTOMERS__UPDATE_GROUPS_LIST_LOADING", true);
    context.commit("CUSTOMERS__UPDATE_GROUPS_PAGE_NO", payload.page);
    context.commit("CUSTOMERS__UPDATE_GROUPS_PAGE_SIZE", payload.count);
    axios
      .get(`${context.getters.getUrl}/v1/CustomerGroups`, {
        params: {
          page: payload.page,
          count: payload.count,
          phrase: payload.phrase,
          language: payload.language,
        },
      })
      .then((response) => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LIST", response.data.items);
        context.commit("CUSTOMERS__UPDATE_GROUPS_TOTAL", response.data.count);
      })
      .catch((error) => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LIST", []);
        console.log(error);
      })
      .finally(() => {
        context.commit("CUSTOMERS__UPDATE_GROUPS_LIST_LOADING", false);
      });
  },
};
