export default {
  PROJECTS__UPDATE_LOADING(state, value) {
    state.loading = value;
  },
  PROJECTS__UPDATE_LIST_LOADING(state, value) {
    state.listLoading = value;
  },
  PROJECTS__UPDATE_LIST(state, value) {
    state.items = value;
  },
  PROJECTS__UPDATE_TOTAL(state, value) {
    state.total = value;
  },
  PROJECTS__UPDATE_PAGE_NO(state, value) {
    state.pageNo = value;
  },
  PROJECTS__UPDATE_PAGE_SIZE(state, value) {
    state.pageSize = value;
  },
  PROJECTS__UPDATE_ITEM(state, value) {
    state.item = value;
  },
  PROJECTS__REFRESH_LIST(state) {
    state.refreshList = !state.refreshList;
  },
  PROJECTS__REFRESH_GROUPS_LIST(state) {
    state.groups.refreshList = !state.groups.refreshList;
  },
  PROJECTS__UPDATE_GROUPS_LOADING(state, value) {
    state.groups.loading = value;
  },
  PROJECTS__UPDATE_GROUPS_LIST_LOADING(state, value) {
    state.groups.listLoading = value;
  },
  PROJECTS__UPDATE_GROUPS_LIST(state, value) {
    state.groups.items = value;
  },
  PROJECTS__UPDATE_GROUPS_TOTAL(state, value) {
    state.groups.total = value;
  },
  PROJECTS__UPDATE_GROUPS_PAGE_NO(state, value) {
    state.groups.pageNo = value;
  },
  PROJECTS__UPDATE_GROUPS_PAGE_SIZE(state, value) {
    state.groups.pageSize = value;
  },
};
