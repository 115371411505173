import CustomerList from "../components/CustomerList.vue";
import Customer from "../components/Customer.vue";
import CustomerGroupsList from "../components/CustomerGroupList.vue";

export const customerRoutes = [
  {
    path: "/customers/list",
    component: CustomerList,
    name: "CustomersList",
  },
  {
    path: "/customers/groups",
    component: CustomerGroupsList,
    name: "CustomerGroupsList",
  },
  {
    path: "/customers/:id",
    component: Customer,
    name: "Customer",
    props: true,
  },
];
