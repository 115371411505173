import { render, staticRenderFns } from "./TemplateManager.vue?vue&type=template&id=2c7cb216&scoped=true"
import script from "./TemplateManager.vue?vue&type=script&lang=js"
export * from "./TemplateManager.vue?vue&type=script&lang=js"
import style0 from "./TemplateManager.vue?vue&type=style&index=0&id=2c7cb216&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c7cb216",
  null
  
)

export default component.exports