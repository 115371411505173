<template>
  <v-card class="rounded-lg" :loading="getLoading">
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue-darken-1" variant="text" @click="closeDelete"
            >لغو</v-btn
          >
          <v-btn color="error" variant="text" @click="deleteItemConfirm"
            >بله</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-progress-linear
      indeterminate
      color="green darken-1"
      slot="progress"
    ></v-progress-linear>

    <v-card-actions>
      <v-row>
        <v-col cols="6">
          <v-card-title>{{ title }}</v-card-title>
        </v-col>
        <v-col cols="6" class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-3 mt-3"
                color="warning"
                dark
                v-bind="attrs"
                v-on="on"
                v-on:click="sendShowHelp"
              >
                mdi-help-circle-outline
              </v-icon>
            </template>
            <span>راهنما</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-btn v-if="add" class="mr-2 success" @click="openFormAdd">
            <v-icon small>mdi-plus</v-icon>
            افزودن
          </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-title>
      <v-text-field
        v-model="searchBox"
        outlined
        dense
        append-icon="mdi-magnify"
        label="جستجو"
        @input="sendSearchBox"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <!-- //////////////////////////////////////// -->

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="addForm"
      >
        <v-card class="rounded-lg">
          <v-card-title-close @close="addForm = false">
            ایجاد
          </v-card-title-close>
          <slot name="addForm"></slot>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="addItem()" color="success">افزودن</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="editForm"
      >
        <v-card class="rounded-lg">
          <v-card-title-close @close="editForm = false">
            ویرایش
          </v-card-title-close>
          <slot name="editForm"></slot>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="editItem()" color="success">ویرایش</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="hasAttachment"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="attachmentForm"
      >
        <v-card>
          <v-card-title-close @close="attachmentForm = false">
            پیوست
          </v-card-title-close>
          <slot name="attachmentForm"></slot>
          <v-divider></v-divider>
          <v-card-actions>
            <v-file-input
              v-model="image"
              color="deep-purple-accent-4"
              counter
              label="انتخاب فایل"
              placeholder="انتخاب فایل"
              prepend-icon="mdi-paperclip"
              variant="outlined"
              :show-size="1000"
              v-show="true"
              ref="fileInput"
              accept="image/*"
            ></v-file-input>

            <v-col cols="12" lg="3">
              <v-btn
                class="mx-1 my-1"
                compact
                color="success"
                v-on:click="uploadAttachment()"
                :disabled="this.image == null"
              >
                <v-icon small>mdi-undo</v-icon>
                آپلود
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="FormChangeLogo"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="FormChangeLogo"
      >
        <v-card>
          <v-card-title-close @close="FormChangeLogo = false">
            لوگو
          </v-card-title-close>
          <slot name="FormChangeLogo"></slot>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="changeLogo()" color="success">ذخیره</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="FormAccess"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="FormAccess"
      >
        <v-card>
          <v-card-title-close @close="FormAccess = false">
            ویرایش
          </v-card-title-close>
          <slot name="FormAccess"></slot>
          <v-divider></v-divider>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="editItem()" color="sucess">ویرایش</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="hasPayment"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="paymentForm"
      >
        <v-card>
          <v-card-title-close @close="paymentForm = false">
            دریافت و پرداخت های قراداد
          </v-card-title-close>
          <slot name="paymentForm"></slot>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="hasSupplement"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="supplementForm"
      >
        <v-card>
          <v-card-title-close @close="supplementForm = false">
            متمم های قرارداد
          </v-card-title-close>
          <slot name="supplementForm"></slot>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="hasAction"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="actionForm"
      >
        <v-card>
          <v-card-title-close @close="actionForm = false">
            فعالیت های قرارداد
          </v-card-title-close>
          <slot name="actionForm"></slot>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <v-dialog
        v-if="hasContacts"
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="contactsForm"
      >
        <v-card>
          <v-card-title-close @close="contactsForm = false">
            تماس های مشتری
          </v-card-title-close>
          <slot name="contactsForm"></slot>
        </v-card>
      </v-dialog>

      <!-- //////////////////////////////////////// -->

      <div>
        <v-data-table
          :headers="headers"
          :items="items.records"
          hide-default-footer
          :items-per-page="size"
          :server-items-length="items.record_count"
          :page="1"
          :fixed-header="true"
        >
          <template v-slot:[`item.index`]="{ index }">
            {{ startIndex + index + 1 }}
          </template>
          <template v-slot:[`item.orgPostFk`]="{ item }">
            <span dense>{{ getOrgPostFk(item.orgPostFk) }}</span>
          </template>
          <template v-slot:[`item.andikatorFk`]="{ item }">
            <span dense>{{ getAndikatorFk(item.andikatorFk) }}</span>
          </template>
          <template v-slot:[`item.eCEEmailFk`]="{ item }">
            <span dense>{{ getECEEmailFk(item.eCEEmailFk) }}</span>
          </template>
          <template v-slot:[`item.manCompanyFk`]="{ item }">
            <span dense>{{ getManCompanyFk(item.manCompanyFk) }}</span>
          </template>
          <template v-slot:[`item.systemFk`]="{ item }">
            <span dense>{{ getSystemFk(item.systemFk) }}</span>
          </template>
          <!-- <template v-slot:[`item.contractType`]="{ item }">
            <span dense>{{ getContractType(item.contractType) }}</span>
          </template> -->
          <template v-slot:[`item.orgUnitFk`]="{ item }">
            <span dense>{{ getOrgUnitFk(item.orgUnitFk) }}</span>
          </template>
          <template v-slot:[`item.projectGroupFk`]="{ item }">
            <span dense>{{ getProjectGroupFk(item.projectGroupFk) }}</span>
          </template>
          <template v-slot:[`item.parentFk`]="{ item }">
            <span dense>{{ getAccountsFk(item.parentFk) }}</span>
          </template>
          <template v-slot:[`item.orgUnitId`]="{ item }">
            <span dense>{{ getOrgUnit(item.orgUnitId) }}</span>
          </template>
          <template v-slot:[`item.parentOrgUnitFk`]="{ item }">
            <span dense>{{ getOrgUnit(item.parentOrgUnitFk) }}</span>
          </template>
          <template v-slot:[`item.bankFk`]="{ item }">
            <span dense>{{ getBankTitle(item.bankFk) }}</span>
          </template>
          <template v-slot:[`item.createDate`]="{ item }">
            <span dense>{{ formatDate(item.createDate) }}</span>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            <span dense>{{ toPersianDate(item.date) }}</span>
          </template>
          <template v-slot:[`item.startDate`]="{ item }">
            <span dense>{{ toPersianDate(item.startDate) }}</span>
          </template>
          <template v-slot:[`item.finishDate`]="{ item }">
            <span dense>{{ toPersianDate(item.finishDate) }}</span>
          </template>
          <template v-slot:[`item.isPublic`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.isPublic"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.in`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.in"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.out`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.out"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.isActive`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.isActive"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.licenseToSendMail`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.licenseToSendMail"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.externalDomain`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.externalDomain"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.isDefault`]="{ item }">
            <v-simple-checkbox
              disabled
              dense
              v-model="item.isDefault"
            ></v-simple-checkbox>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  v-if="hasAttachment"
                  @click="openFormAttachment(item)"
                >
                  mdi-paperclip
                </v-icon>
              </template>
              <span>پیوست</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-if="hasLogo"
                  dense
                  class="ma-1"
                  @click="openFormChangeLogo(item)"
                >
                  mdi-sync
                </v-icon>
              </template>
              <span>تغییر لوگو</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  v-if="hasAccess"
                  @click="openFormAccess(item)"
                >
                  mdi-cancel
                </v-icon>
              </template>
              <span>محدودیت دسترسی</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="hasPayment"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  @click="openPaymentForm(item)"
                >
                  mdi-cash
                </v-icon>
              </template>
              <span>دریافت و پرداخت</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="hasSupplement"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  @click="openSupplementForm(item)"
                >
                  mdi-code-braces
                </v-icon>
              </template>
              <span>متمم قرارداد</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="hasAction"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  @click="openActionForm(item)"
                >
                  mdi-anchor
                </v-icon>
              </template>
              <span>فعالیت قرارداد</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="hasContacts"
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  @click="openContactsForm(item)"
                >
                  mdi-phone-hangup
                </v-icon>
              </template>
              <span>تماس ها</span>
            </v-tooltip>
            <v-tooltip v-if="edit" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  dense
                  class="ma-1"
                  v-bind="attrs"
                  v-on="on"
                  @click="openFormEdit(item)"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>ویرایش</span>
            </v-tooltip>
            <v-tooltip v-if="remove" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  dense
                  class="ma-1"
                  color="error"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>حذف</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <div class="text-center pt-2"></div>
        <v-row v-if="pageLength > 0">
          <v-col cols="12">
            <v-pagination
              v-model="page"
              :length="pageLength"
              @input="handlePageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import VCardTitleClose from "./VCardTitleClose";
import { myMixin } from "../../mixins.js";
import { ref } from "vue";
export default {
  mixins: [myMixin],
  name: "AccountingSystem",
  components: {
    VCardTitleClose,
  },
  data() {
    return {
      page: 1,
      pageSize: 10,
      id: null,
      selectedFile: null,
      addForm: false,
      editForm: false,
      attachmentForm: false,
      FormChangeLogo: false,
      FormAccess: false,
      paymentForm: false,
      supplementForm: false,
      actionForm: false,
      contactsForm: false,
      editData: null,
      searchBox: "",
      image: null,
      dialogDelete: false,
      itemToDelete: null,
      selectedItem: null,
    };
  },
  props: [
    "title",
    "headers",
    "add",
    "edit",
    "remove",
    "items",
    "insertItemData",
    "editItemData",
    "removeItemData",
    "hasAttachment",
    "hasPayment",
    "hasSupplement",
    "hasAction",
    "hasContacts",
    "hasLogo",
    "hasAccess",
    "onAttachmentUpload",
    "size",
  ],
  methods: {
    getManCompanyFk(id) {
      let result = this.gecompanyList.records.find((item) => {
        return item.manCompanyId == id;
      });

      if (result) {
        return result.fullName;
      }
    },
    getECEEmailFk(id) {
      let result = this.getEceemails.records.find((item) => {
        return item.eCEEmailId == id;
      });

      if (result) {
        return result.title;
      }
    },
    getAndikatorFk(id) {
      let result = this.getAndikatorsList.records.find((item) => {
        return item.andikatorId == id;
      });
      if (result) {
        return result.andikatorTitle;
      }
    },
    getOrgPostFk(id) {
      let result = this.getorgpostList.records.find((item) => {
        return item.orgPostId == id;
      });
      if (result) {
        return result.orgPostTitle;
      }
    },
    getSystemFk(id) {
      let result = this.getSystemList.records.find((item) => {
        return item.id == id;
      });
      return result.title;
    },
    getContractType(id) {
      let result = this.getContractgroupList.records.find((item) => {
        return item.contractType == id;
      });

      if (result) {
        return result.contractGroupTitle;
      }
    },
    getProjectGroupFk(id) {
      let result = this.getProjectgroup.records.find((item) => {
        return item.projectGroupId == id;
      });
      if (result) {
        return result.projectGroupTitle;
      }
    },
    getAccountsFk(id) {
      if (id == null) return "";
      let result = this.getAccountsList.records.find((item) => {
        return item.accountId == id;
      });
      if (result) {
        return result.title;
      }
    },
    getOrgUnitFk(id) {
      let result = this.getorgunitList.records.find((item) => {
        return item.orgUnitId == id;
      });

      if (result) {
        return result.orgUnitTitle;
      }
    },
    getOrgUnit(id) {
      let result = this.getorgunitList.records.find((item) => {
        return item.orgUnitId == id;
      });

      if (result) return result.orgUnitTitle;
    },
    getBankTitle(id) {
      let result = this.getBankList.records.find((item) => {
        return item.bankId == id;
      });
      return result?.title;
    },
    sendSearchBox() {
      this.$emit("getListBySearch", this.searchBox);
    },
    sendShowHelp() {
      this.$emit("ShowHelp", true);
    },
    formatDate(date) {
      return this.toPersianDateTime(date);
    },
    uploadAttachment(/*e*/) {
      this.$emit("onAttachmentUpload", {
        attachment: this.image,
        item: this.id,
      });
      this.image = null;
    },
    handlePageChange(page) {
      this.$emit("getList", page);
    },
    openFormAdd() {
      this.addForm = true;
    },
    addItem() {
      if (
        this.insertItemData.formName &&
        this.$parent.$refs[this.insertItemData.formName]
      ) {
        if (!this.$parent.$refs[this.insertItemData.formName].validate())
          return;
      }
      this.$store.dispatch("sendNewitem", { data: this.insertItemData });
      this.addForm = false;
    },
    openFormEdit(item) {
      this.editForm = true;
      this.editData = item;
      this.$emit("getItem", item);
    },
    openFormAttachment(item) {
      this.image = null;
      this.attachmentForm = true;
      this.id = item;
      this.$emit("getItem", item);
    },
    openFormChangeLogo(item) {
      this.FormChangeLogo = true;
      this.selectedItem = item;
      this.$emit("getItem", item);
    },
    closeFormChangeLogo() {
      this.FormChangeLogo = false;
    },
    openFormAccess(item) {
      this.FormAccess = true;
      this.$emit("getItem", item);
    },
    openPaymentForm(item) {
      this.paymentForm = true;
      this.$emit("getItem", item);
      this.$store.dispatch("sendListContractPayment", {
        url: "contractpayment/list/",
        id: item.ContractId,
      });
    },
    openSupplementForm(item) {
      this.supplementForm = true;
      // console.log(item , 'sssdsd');
      this.$emit("getItem", item);
      this.$store.dispatch("sendListContractSupplement", {
        url: "contractcoefficient/list/",
        id: item.contractId,
      });
    },
    openActionForm(item) {
      this.actionForm = true;
      this.$emit("getItem", item);
      this.$store.dispatch("sendListContractAction", {
        url: "contractactivity/list/",
        id: item.contractId,
      });
    },
    openContactsForm(item) {
      this.contactsForm = true;
      this.$emit("getItem", item);
      this.$store.dispatch("sendListCustomerContact", {
        url: "customercontact/list/",
        id: item.customerId,
      });
    },
    changeLogo() {
      this.$emit("changeLogo", this.selectedItem);
    },
    editItem() {
      if (
        this.editItemData.formName &&
        this.$parent.$refs[this.editItemData.formName]
      ) {
        if (!this.$parent.$refs[this.editItemData.formName].validate()) return;
      }

      this.$store.dispatch("sendEditItem", {
        data: this.editItemData,
        id: this.editData,
      });
      this.editForm = false;
    },
    deleteItem(item) {
      this.itemToDelete = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.$store.dispatch("sendRemoveItem", {
        data: this.removeItemData,
        id: this.itemToDelete,
      });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
    },
  },
  mounted() {
    if (this.size == 0) this.pageSize = ref(10);
    if (this.size == -1) this.pageSize = ref(-1);
  },
  computed: {
    ...mapGetters([
      "gecompanyList",
      "getAndikatorsList",
      "getEceemails",
      "getLoading",
      "getBankList",
      "getorgunitList",
      "getorgpostList",
      "getProjectgroup",
      "getAccountsList",
      "getContractgroupList",
      "getSystemList",
    ]),
    pageLength() {
      return this.pageSize > 0
        ? Math.ceil(this.items.record_count / this.pageSize)
        : 0;
    },
  },
  watch: {
    search() {},
  },
};
</script>
